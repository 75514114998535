@charset "UTF-8";
/**
 * Copyright 2020 Product Field Works GmbH. All rights reserved.
 *
 * This software is proprietary and confidential. Redistribution
 * not permitted. Unless required by applicable law or agreed to
 * in writing, software distributed on an "AS IS" BASIS, WITHOUT-
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 */
body {
  --font-family-default: "Söhne", Helvetica, Arial, -apple-system, sans-serif;
  --font-family-wide: "Söhne Breit", monospace;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --spacing-unit: 6px;
  --white: #ffffff;
  --gray-100: #f9f9fb;
  --gray-150: #f3f3f7;
  --gray-200: #e7e8ee;
  --gray-300: #d9dbe3;
  --gray-400: #c3c5d0;
  --gray-500: #9ea0ae;
  --gray-600: #747786;
  --gray-700: #545763;
  --gray-800: #373943;
  --gray-900: #1f2128;
  --gray-950: #181a20;
  --black: #000000;
  --red: #ff2c00;
  --green: #00dbc2;
  --green-dark: #00ad99;
  --highlight: #2560eb;
  --highlight-light: #5588ff; }

.pulsating-text strong,
.pulsating-text--dark strong {
  --base-color: #c6d7ff;
  --highlight-color: var(--highlight-light);
  color: var(--base-color); }

body:not(.is-safari) .pulsating-text strong,
body:not(.is-safari) .pulsating-text--dark strong {
  background: linear-gradient(to right, var(--base-color) 20%, var(--highlight-color) 30%, var(--highlight-color) 40%, var(--base-color) 50%);
  background-size: 300% auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 6s linear infinite; }

@keyframes shine {
  to {
    background-position: -300% center; } }

.pulsating-text--dark strong {
  --base-color: #454e61;
  --highlight-color: var(--highlight); }

.button {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  text-decoration: none;
  background-color: var(--gray-300);
  color: var(--black);
  border: 1px solid transparent;
  display: block;
  border-radius: 3px;
  padding: calc(var(--spacing-unit) * 0.66) calc(var(--spacing-unit) * 2.5);
  transition: all 0.2s;
  cursor: pointer; }
  .button:focus:not(:active) {
    outline: none;
    box-shadow: 0 0 0 2px var(--red), 0 0 20px 0 var(--red); }
  .button:active {
    opacity: 0.85;
    transform: scale(0.97); }

.button--primary {
  font-weight: var(--font-weight-medium);
  background-color: var(--highlight);
  color: var(--white); }

.button--animated {
  color: var(--white);
  background: linear-gradient(-40deg, var(--highlight) 25%, #537ee0 30%, #537ee0 35%, var(--highlight) 40%);
  background-size: 800% auto;
  animation: buttonShine 32s linear infinite; }

@keyframes buttonShine {
  to {
    background-position: -800% center; } }

.button--secondary {
  background-color: transparent;
  color: var(--black);
  border-color: currentColor; }
  .button--secondary.button--light {
    color: var(--white); }

.button-group {
  display: flex;
  flex-wrap: wrap;
  column-gap: calc(var(--spacing-unit) * 1.5);
  row-gap: calc(var(--spacing-unit) * 1.5); }

.cookie-banner {
  background-color: var(--black);
  color: var(--white);
  position: fixed;
  bottom: calc(var(--spacing-unit) * 3);
  right: calc(var(--spacing-unit) * 5);
  z-index: 5000;
  padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 3);
  max-width: calc(var(--spacing-unit) * 60);
  transform: perspective(0px) translateZ(100px); }
  @media (max-width: 599px) {
    .cookie-banner {
      right: 0;
      left: 0;
      bottom: 0;
      max-width: 100%;
      width: 100%;
      padding: calc(var(--spacing-unit) * 1.5) calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 3); } }

.cookie-banner__actions {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  column-gap: calc(var(--spacing-unit) * 1.5);
  margin-top: calc(var(--spacing-unit) * 3); }
  @media (max-width: 599px) {
    .cookie-banner__actions {
      margin-top: calc(var(--spacing-unit) * 2); } }

@media (max-width: 599px) {
  .cookie-banner__actions .button {
    padding: calc(var(--spacing-unit) * 0.5) calc(var(--spacing-unit) * 2); } }

.cookie-banner--hidden {
  display: none; }

.features-teaser {
  grid-template-rows: [headline] auto [photo] calc(var(--spacing-unit) * 20) [overlap] auto [screenshot] calc(var(--spacing-unit) * 13) [read-more] auto [overlap-2] calc(var(--spacing-unit) * 8); }
  @media (max-width: 1200px) {
    .features-teaser {
      grid-template-rows: [headline] auto [photo] calc(var(--spacing-unit) * 20) [overlap] auto [screenshot] calc(var(--spacing-unit) * 13) [features-list] auto [read-more] auto [overlap-2] calc(var(--spacing-unit) * 8); } }
  @media (max-width: 599px) {
    .features-teaser {
      grid-template-rows: [headline] auto [photo] calc(var(--spacing-unit) * 20) [overlap] auto [screenshot] calc(var(--spacing-unit) * 13) [features-list] auto [read-more] auto [overlap-2] calc(var(--spacing-unit) * 5); } }

.features-teaser__keyvisual {
  grid-row: overlap / -1;
  background-color: var(--gray-800);
  grid-column: 6 / -2;
  z-index: -1;
  position: relative;
  overflow: hidden; }
  @media (max-width: 1200px) {
    .features-teaser__keyvisual {
      grid-column: 15 / -1; } }
  @media (max-width: 599px) {
    .features-teaser__keyvisual {
      grid-column: 4 / -1; } }

.features-teaser__background {
  grid-row: headline / -2;
  grid-column: 2 / -7;
  z-index: -1;
  background-color: var(--gray-150); }
  @media (max-width: 1200px) {
    .features-teaser__background {
      grid-column: 2 / -6; } }
  @media (max-width: 900px) {
    .features-teaser__background {
      grid-column: 2 / -4; } }
  @media (max-width: 599px) {
    .features-teaser__background {
      grid-column: 1 / -3; } }

.features-teaser__intro {
  grid-row: headline / photo;
  grid-column: 2 / 9;
  padding: calc(var(--spacing-unit) * 7);
  padding-right: calc(var(--spacing-unit) * 12); }
  @media (max-width: 1200px) {
    .features-teaser__intro {
      grid-column: 2 / -6;
      padding-bottom: calc(var(--spacing-unit) * 10); } }
  @media (max-width: 599px) {
    .features-teaser__intro {
      padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 4);
      padding-bottom: calc(var(--spacing-unit) * 5);
      padding-left: 0; } }

.features-teaser__headline {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  font-weight: var(--font-weight-medium);
  color: var(--black);
  hyphens: manual;
  -webkit-hyphens: manual; }
  @media (max-width: 800px) {
    .features-teaser__headline {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }
  .features-teaser__headline strong {
    font-weight: var(--font-weight-medium);
    color: var(--highlight); }

.features-teaser__list {
  grid-row: photo / read-more;
  grid-column: 2 / 8;
  align-self: end;
  padding: 0 calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 7); }
  @media (max-width: 1200px) {
    .features-teaser__list {
      grid-row: features-list;
      grid-column: 2 / -6;
      display: flex;
      flex-wrap: wrap;
      column-gap: calc(var(--spacing-unit) * 10);
      row-gap: calc(var(--spacing-unit) * 6); } }
  @media (max-width: 900px) {
    .features-teaser__list {
      grid-column: 2 / -4; } }
  @media (max-width: 599px) {
    .features-teaser__list {
      padding: 0;
      padding-right: calc(var(--spacing-unit) * 4);
      padding-bottom: 0;
      row-gap: calc(var(--spacing-unit) * 4); } }

.features-teaser__list-item {
  opacity: 0.3;
  transition: all 0.7s;
  transform: scale(0.92);
  filter: saturate(0);
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  position: relative; }
  @media (max-width: 599px) {
    .features-teaser__list-item {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  .features-teaser__list-item::before {
    content: "";
    position: absolute;
    left: calc(var(--spacing-unit) * -2.5);
    top: 5px;
    bottom: 5px;
    border-left: 2px solid rgba(0, 0, 0, 0.2); }
  .features-teaser__list-item::after {
    content: "";
    position: absolute;
    left: calc(var(--spacing-unit) * -2.5);
    top: 5px;
    bottom: 5px;
    transform: scaleY(0);
    transform-origin: top center;
    border-left: 2px solid var(--highlight); }
  @media (max-width: 900px) {
    .features-teaser__list-item {
      opacity: 1;
      transform: scale(1);
      filter: none;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.009em;
      text-underline-offset: 1.5px; } }
  @media (max-width: 599px) {
    .features-teaser__list-item {
      margin-left: calc(var(--spacing-unit) * 2.5);
      width: 100%; } }

.features-teaser__list-item--active {
  opacity: 1;
  transform: scale(1);
  filter: none; }
  .features-teaser__list-item--active::after {
    transform: scaleY(1); }

.features-teaser--automation .features-teaser__list-item--active::after {
  transition: transform 3.9s linear; }

.features-teaser__list-item + .features-teaser__list-item {
  margin-top: calc(var(--spacing-unit) * 2); }
  @media (max-width: 1200px) {
    .features-teaser__list-item + .features-teaser__list-item {
      margin-top: 0; } }

.features-teaser__list-kicker {
  color: var(--highlight);
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  font-weight: var(--font-weight-medium);
  display: table;
  margin-bottom: calc(var(--spacing-unit) * 1); }

.features-teaser__list-slogan {
  margin-bottom: 0; }

.features-teaser__list-link {
  display: inline-block;
  color: var(--gray-600);
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  margin-top: calc(var(--spacing-unit) * 1);
  text-decoration: none; }
  .features-teaser__list-link:hover {
    text-decoration: underline; }

.features-teaser__list-link-arrow {
  display: inline-block;
  margin-left: 2px; }
  .features-teaser__list-link-arrow svg {
    height: 9px; }
  .features-teaser__list-link-arrow path {
    fill: var(--gray-800); }

.features-teaser__screenshot {
  grid-row: 1 / read-more;
  grid-column: 8 / -3;
  padding-top: calc(var(--spacing-unit) * 5);
  padding-right: calc(var(--spacing-unit) * 3);
  padding-left: calc(var(--spacing-unit) * 2);
  align-self: center;
  height: 100%;
  display: grid; }
  @media (max-width: 1200px) {
    .features-teaser__screenshot {
      grid-column: 3 / -3;
      grid-row: photo / features-list;
      padding-top: 0;
      padding-bottom: calc(var(--spacing-unit) * 8);
      padding-right: 0;
      padding-left: 0; } }
  @media (max-width: 599px) {
    .features-teaser__screenshot {
      grid-column: 2 / -2;
      padding-bottom: calc(var(--spacing-unit) * 6); } }

.features-teaser__screenshot-wrapper {
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.25);
  transform: perspective(1000px) rotateX(5deg) rotateY(-6deg) rotateZ(0deg) translateZ(90px) scale(0.97);
  transition: transform 1.3s;
  border-radius: 3px;
  overflow: hidden;
  background-color: var(--gray-800);
  align-self: center;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  transform-style: flat; }

.features-teaser__screenshot-wrapper[data-active-feature="Explore"] {
  transform: perspective(1000px) rotateX(1deg) rotateY(-5deg) rotateZ(0deg) translateZ(90px) scale(0.97); }

.features-teaser__screenshot-wrapper[data-active-feature="Present"] {
  transform: perspective(1000px) rotateX(-3deg) rotateY(-6deg) rotateZ(0deg) translateZ(90px) scale(0.97); }

.features-teaser__screenshot-image {
  border-radius: 3px;
  grid-row: 1;
  grid-column: 1;
  opacity: 0;
  width: 100%;
  display: block;
  transition: opacity 0.8s; }
  .features-teaser__screenshot-image img {
    display: block;
    width: 100%;
    height: 100%; }

.features-teaser__screenshot-image--active {
  opacity: 1; }

.features-teaser__read-more {
  grid-row: read-more;
  grid-column: 2 / -7;
  color: var(--white);
  padding: calc(var(--spacing-unit) * 7) calc(var(--spacing-unit) * 8); }
  @media (max-width: 1200px) {
    .features-teaser__read-more {
      grid-column: 2 / -6; } }
  @media (max-width: 900px) {
    .features-teaser__read-more {
      grid-column: 2 / -6; } }
  @media (max-width: 599px) {
    .features-teaser__read-more {
      grid-column: 2 / -3;
      padding: calc(var(--spacing-unit) * 3);
      padding-left: 0;
      margin-top: calc(var(--spacing-unit) * 4);
      margin-bottom: calc(var(--spacing-unit) * 2); }
      .features-teaser__read-more .fruther-reading {
        grid-template-columns: 1fr; }
      .features-teaser__read-more .fruther-reading__intro {
        margin-bottom: calc(var(--spacing-unit) * 2); } }

.footer {
  color: var(--white);
  margin-top: calc(var(--spacing-unit) * 25);
  grid-template-rows: [card] auto [links] 1fr; }
  @media (max-width: 1200px) {
    .footer {
      grid-template-rows: [cta] auto [card] calc(var(--spacing-unit) * 8) [links] 1fr; } }
  @media (max-width: 800px) {
    .footer {
      grid-template-rows: [cta] auto [card] auto [links] 1fr; } }
  @media (max-width: 599px) {
    .footer {
      margin-top: calc(var(--spacing-unit) * 20); } }

.footer__background {
  grid-column: 1 / -1;
  grid-row: links / -1;
  background-color: var(--gray-900); }

.footer__cta {
  grid-column: 17/-2;
  grid-row: card;
  color: var(--black);
  padding-top: calc(var(--spacing-unit) * 5);
  padding-left: calc(var(--spacing-unit) * 8);
  padding-bottom: calc(var(--spacing-unit) * 15); }
  @media (max-width: 1200px) {
    .footer__cta {
      grid-row: cta;
      grid-column: 2/-2;
      padding-top: 0;
      padding-left: 0;
      padding-bottom: calc(var(--spacing-unit) * 8); } }

.footer__cta-intro {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  max-width: 20em;
  margin-bottom: 1em;
  position: relative;
  z-index: 2; }
  @media (max-width: 599px) {
    .footer__cta-intro {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  .footer__cta-intro strong {
    font-weight: var(--font-weight-regukar); }

.footer__card {
  grid-column: 2 / 17;
  grid-row: card / -1;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr;
  overflow: hidden; }
  @media (max-width: 800px) {
    .footer__card {
      grid-column: 2 / -2;
      grid-row: card; } }

.footer__card-slogan {
  grid-row: 1;
  grid-column: 1;
  position: relative;
  z-index: 3;
  padding: calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 6);
  padding-bottom: 0;
  max-width: 15em;
  font-size: 50px;
  line-height: 54px;
  letter-spacing: -0.02em;
  font-weight: var(--font-weight-medium);
  hyphens: manual;
  -webkit-hyphens: manual; }
  @media (max-width: 800px) {
    .footer__card-slogan {
      font-size: 32px;
      line-height: 35px;
      letter-spacing: -0.02em; } }
  .footer__card-slogan strong {
    color: var(--green); }
  @media (max-width: 599px) {
    .footer__card-slogan {
      padding: calc(var(--spacing-unit) * 3); } }

.footer__card-cta {
  display: none;
  z-index: 2;
  padding: calc(var(--spacing-unit) * 15) calc(var(--spacing-unit) * 10) 0 calc(var(--spacing-unit) * 5); }

.footer__card-cta-intro {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  max-width: 20em;
  margin-bottom: 1em;
  position: relative;
  z-index: 2;
  color: var(--white); }
  @media (max-width: 599px) {
    .footer__card-cta-intro {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  .footer__card-cta-intro strong {
    font-weight: var(--font-weight-regukar); }

.footer__card-cta-button {
  position: relative; }
  .footer__card-cta-button .button {
    position: relative;
    z-index: 2; }

.footer__card-illustration {
  grid-row: 2;
  grid-column: 1;
  z-index: 2;
  padding: 0 calc(var(--spacing-unit) * 5);
  margin-top: -5%;
  pointer-events: none; }
  @media (max-width: 1200px) {
    .footer__card-illustration {
      margin-top: 0; } }
  @media (max-width: 599px) {
    .footer__card-illustration {
      padding: 0 calc(var(--spacing-unit) * 2); } }

.footer__card-illustration-image {
  max-width: 100%;
  display: block; }

.footer__card-illustration-filler {
  grid-row: 3;
  grid-column: 1;
  margin: 0 calc(var(--spacing-unit) * 5);
  background-repeat: repeat-y;
  background-size: 100%;
  min-height: calc(var(--spacing-unit) * 8);
  z-index: 2; }
  @media (max-width: 599px) {
    .footer__card-illustration-filler {
      margin: 0 calc(var(--spacing-unit) * 2); } }

.footer__card-background {
  grid-row: 1 / -2;
  grid-column: 1 / -1;
  background-color: var(--gray-800);
  position: relative;
  overflow: hidden;
  z-index: 0; }
  .footer__card-background .key-visual {
    opacity: 0.15; }

.footer__content {
  grid-column: 17 / -2;
  grid-row: links;
  padding-top: calc(var(--spacing-unit) * 6);
  padding-left: calc(var(--spacing-unit) * 8);
  padding-bottom: calc(var(--spacing-unit) * 5);
  display: grid;
  grid-template-rows: 1fr auto; }
  @media (max-width: 800px) {
    .footer__content {
      grid-column: 2 / -2;
      padding-left: 0;
      padding-bottom: calc(var(--spacing-unit) * 5); } }

.footer__link-lists {
  display: flex;
  flex-wrap: wrap;
  align-content: start;
  column-gap: calc(var(--spacing-unit) * 9);
  row-gap: calc(var(--spacing-unit) * 4); }

.footer__link-list-title {
  color: var(--gray-500);
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  font-weight: var(--font-weight-medium);
  margin-bottom: calc(var(--spacing-unit) * 1); }

.footer__link-list-title--languages {
  color: var(--green); }

.footer__link-list a {
  text-decoration: none;
  transition: opacity 0.2s;
  display: block;
  padding: 2px 0; }
  .footer__link-list a:hover {
    opacity: 0.7; }
  .footer__link-list a.active {
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-color: var(--gray-600);
    opacity: 0.7; }

.footer__pricing {
  width: 100%; }

.footer__pricing-text {
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  max-width: 22em; }

.footer__legal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: calc(var(--spacing-unit) * 7);
  row-gap: calc(var(--spacing-unit) * 3);
  margin-top: calc(var(--spacing-unit) * 6); }
  @media (max-width: 599px) {
    .footer__legal {
      justify-content: center; } }

.footer__link-list--legal {
  display: flex;
  flex-wrap: wrap;
  column-gap: calc(var(--spacing-unit) * 3);
  row-gap: calc(var(--spacing-unit) * 1);
  color: var(--gray-600); }

.footer__logo {
  text-decoration: none; }
  .footer__logo img {
    display: block;
    float: right; }

@media (max-width: 1200px) {
  .footer--large {
    grid-template-rows: [card] auto [links] 1fr; } }

@media (max-width: 800px) {
  .footer--large {
    grid-template-rows: [card] auto [links] 1fr; } }

.footer--large .footer__card {
  grid-column: 2 / -2;
  grid-row: card;
  grid-template-columns: 1fr 2fr 1fr 1fr; }
  @media (max-width: 1200px) {
    .footer--large .footer__card {
      grid-template-rows: auto auto auto 1fr; } }

.footer--large .footer__card-slogan {
  grid-row: 1;
  grid-column: 1 / 3; }
  @media (max-width: 1200px) {
    .footer--large .footer__card-slogan {
      grid-column: 1 / -1; } }

.footer--large .footer__card-cta {
  display: block;
  grid-row: 1;
  grid-column: 3/-1;
  justify-self: end; }
  @media (max-width: 1200px) {
    .footer--large .footer__card-cta {
      grid-column: 1 / -1;
      grid-row: 2;
      justify-self: start;
      padding-top: calc(var(--spacing-unit) * 5); } }
  @media (max-width: 599px) {
    .footer--large .footer__card-cta {
      padding: calc(var(--spacing-unit) * 3);
      padding-top: 0; } }

@media (max-width: 599px) {
  .footer--large .footer__card-cta-intro {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.009em;
    text-underline-offset: 1.5px; } }

.footer--large .footer__card-illustration {
  grid-column: 1 / 4;
  margin-top: -9%; }
  @media (max-width: 1200px) {
    .footer--large .footer__card-illustration {
      grid-row: 3;
      margin-top: 3%;
      grid-column: 2 / -1; } }
  @media (max-width: 599px) {
    .footer--large .footer__card-illustration {
      grid-column: 1 / -1; } }

.footer--large .footer__card-illustration-filler {
  grid-column: 1 / 4; }
  @media (max-width: 1200px) {
    .footer--large .footer__card-illustration-filler {
      grid-row: 4;
      grid-column: 2 / -1; } }
  @media (max-width: 599px) {
    .footer--large .footer__card-illustration-filler {
      grid-column: 1 / -1; } }

.footer--large .footer__cta {
  display: none; }

.footer--large .footer__content {
  grid-column: 2 / -2;
  padding-left: 0;
  padding-bottom: calc(var(--spacing-unit) * 5); }

.footer--minimal {
  margin-top: calc(var(--spacing-unit) * 20); }
  @media (max-width: 1200px) {
    .footer--minimal {
      grid-template-rows: [links] 1fr; } }
  @media (max-width: 800px) {
    .footer--minimal {
      grid-template-rows: [links] 1fr; } }
  @media (max-width: 599px) {
    .footer--minimal {
      margin-top: calc(var(--spacing-unit) * 10); } }
  .footer--minimal .footer__card {
    display: none; }
  .footer--minimal .footer__cta {
    display: none; }
  .footer--minimal .footer__content {
    grid-column: 2 / -2;
    padding-left: 0;
    padding-bottom: calc(var(--spacing-unit) * 3); }

.fruther-reading {
  border-top: 1px solid currentColor;
  padding-top: calc(var(--spacing-unit) * 2);
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: calc(var(--spacing-unit) * 20); }
  @media (max-width: 900px) {
    .fruther-reading {
      column-gap: calc(var(--spacing-unit) * 10); } }

.fruther-reading__intro {
  font-weight: var(--font-weight-medium); }

.fruther-reading__links {
  display: grid;
  column-gap: calc(var(--spacing-unit) * 8);
  grid-auto-flow: column;
  justify-content: start; }
  @media (max-width: 900px) {
    .fruther-reading__links {
      grid-auto-flow: row;
      row-gap: calc(var(--spacing-unit) * 0.5); } }

.grid {
  display: grid;
  grid-template-columns: calc(var(--spacing-unit) * 10) repeat(24, 1fr) calc(var(--spacing-unit) * 10); }
  @media (min-width: 1440px) {
    .grid {
      grid-template-columns: 1fr repeat(24, 55px) 1fr; } }
  @media (max-width: 900px) {
    .grid {
      grid-template-columns: calc(var(--spacing-unit) * 5) repeat(24, 1fr) calc(var(--spacing-unit) * 5); } }
  @media (max-width: 599px) {
    .grid {
      grid-template-columns: calc(var(--spacing-unit) * 2.5) repeat(24, 1fr) calc(var(--spacing-unit) * 2.5); } }

.header {
  position: sticky;
  top: calc(var(--spacing-unit) * -3);
  left: 0;
  right: 0;
  z-index: 99;
  margin-top: calc(var(--spacing-unit) * -1);
  padding-top: calc(var(--spacing-unit) * 5);
  padding-bottom: calc(var(--spacing-unit) * 2);
  background-color: var(--white);
  transition: all 0.4s;
  transform: translateY(0) perspective(1000000px) translateZ(10px); }
  @media (max-width: 599px) {
    .header {
      padding-top: calc(var(--spacing-unit) * 3.5);
      padding-bottom: calc(var(--spacing-unit) * 0.5); } }

html:not([data-scroll="0"]) .header {
  box-shadow: 0 0 25px -10px rgba(0, 0, 0, 0.3);
  transform: scale(1.01) translateY(0) perspective(1000000px) translateZ(200px); }
  @media (max-width: 599px) {
    html:not([data-scroll="0"]) .header {
      transform: translateY(0) perspective(1000000px) translateZ(200px); } }

.header__content {
  width: 100%;
  height: 100%; }

.header__logo {
  grid-row: 1;
  grid-column: 2 / 7;
  justify-self: start;
  align-self: center; }
  .header__logo a,
  .header__logo img {
    display: block; }
  @media (max-width: 1200px) {
    .header__logo {
      grid-column: 2 / 14; } }
  @media (max-width: 599px) {
    .header__logo img {
      width: 80%; } }

.header__nav {
  grid-row: 1;
  grid-column: 7 / -2;
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  align-content: center;
  column-gap: calc(var(--spacing-unit) * 6); }
  @media (max-width: 1200px) {
    .header__nav {
      display: none; } }

.header .nav {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 3px; }

.header .nav__item {
  display: inline-block; }

.header .nav__item + .nav__item {
  margin-left: calc(var(--spacing-unit) * 3); }

.header .nav-link {
  display: inline-block;
  text-decoration: none; }

.header .nav-link--active {
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: var(--highlight); }

.header__nav-show-mobile {
  grid-row: 1;
  grid-column: 14 / -2;
  justify-self: end;
  align-self: start;
  display: none;
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.009em;
  text-underline-offset: 1.5px;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: var(--gray-500);
  padding: calc(var(--spacing-unit) * 1);
  padding-right: 0; }
  @media (max-width: 1200px) {
    .header__nav-show-mobile {
      display: block; } }

.header--light {
  color: var(--white); }

.header__nav--mobile {
  display: none;
  background-color: var(--gray-900);
  color: var(--white);
  box-shadow: 0 0 25px -10px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 101;
  transform: translateY(calc(-100% - var(--spacing-unit) * 4)) perspective(1000000px) translateZ(11px);
  transition: transform 0.5s;
  padding: calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 10) calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 10);
  width: 100%;
  grid-template-columns: min-content 1fr auto;
  grid-template-rows: auto auto;
  row-gap: calc(var(--spacing-unit) * 5); }
  @media (max-width: 1200px) {
    .header__nav--mobile {
      display: grid; } }
  @media (max-width: 900px) {
    .header__nav--mobile {
      padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 5); } }
  @media (max-width: 599px) {
    .header__nav--mobile {
      padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 3); } }
  .header__nav--mobile .nav {
    grid-row: 2;
    grid-column: 1 / -1;
    display: flex;
    flex-wrap: wrap;
    column-gap: calc(var(--spacing-unit) * 4);
    row-gap: calc(var(--spacing-unit) * 2); }
  .header__nav--mobile .nav__item {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.01em;
    font-weight: var(--font-weight-medium);
    color: var(--gray-500);
    transform: translateY(-80%);
    opacity: 0;
    transition: all 0.7s;
    white-space: nowrap; }
    @media (max-width: 800px) {
      .header__nav--mobile .nav__item {
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.02em; } }
    .header__nav--mobile .nav__item:nth-child(2) {
      transition-delay: calc(0.07s * 2); }
    .header__nav--mobile .nav__item:nth-child(3) {
      transition-delay: calc(0.07s * 3); }
    .header__nav--mobile .nav__item:nth-child(4) {
      transition-delay: calc(0.07s * 4); }
    .header__nav--mobile .nav__item:nth-child(5) {
      transition-delay: calc(0.07s * 5); }
    .header__nav--mobile .nav__item:nth-child(6) {
      transition-delay: calc(0.07s * 6); }
  .header__nav--mobile .nav__item + .nav__item {
    margin-left: 0; }
  .header__nav--mobile .nav-link--active {
    color: var(--white);
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-color: var(--highlight); }

.header__nav-close-mobile {
  grid-row: 1;
  grid-column: 3;
  align-self: center;
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.009em;
  text-underline-offset: 1.5px;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: var(--gray-500);
  padding: calc(var(--spacing-unit) * 1);
  padding-right: 0; }

.header__logo--mobile {
  grid-row: 1;
  grid-column: 1;
  justify-self: stretch;
  align-self: center; }
  .header__logo--mobile a {
    display: block; }
  .header__logo--mobile img {
    display: block;
    width: 80px; }

.header__nav-buttons--mobile {
  grid-row: 3;
  grid-column: 1 / -1; }

.header__nav--mobile--visible {
  transform: translateY(0%) perspective(1000000px) translateZ(11px); }
  .header__nav--mobile--visible .nav__item {
    transform: translateY(0%);
    opacity: 1; }

.input {
  font: inherit;
  padding: calc(var(--spacing-unit) * 1) calc(var(--spacing-unit) * 2);
  border: 1px solid var(--black);
  background-color: var(--white);
  border-radius: 3px;
  display: block; }
  .input:focus {
    border-color: var(--green);
    outline: none;
    box-shadow: 0 0 0 1px var(--green), 0 0 20px 0 var(--green); }
  .input::placeholder {
    color: var(--gray-500); }

.input--light {
  color: var(--white);
  border-color: var(--white);
  background-color: var(--gray-900); }
  .input--light::placeholder {
    color: var(--gray-800);
    transition: color 0.2s; }
  .input--light:focus {
    border-color: var(--red);
    outline: none;
    box-shadow: 0 0 0 1px var(--red), 0 0 20px 0 var(--red); }
  .input--light:focus::placeholder {
    color: var(--gray-600); }

.key-visual {
  opacity: 0.3; }

.key-visual__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  min-width: 1440px; }
  @media (max-width: 1200px) {
    .key-visual__image {
      min-width: 1200px; } }
  @media (max-width: 900px) {
    .key-visual__image {
      min-width: 900px; } }
  @media (max-width: 599px) {
    .key-visual__image {
      min-width: 599px; } }

.quicklinks__intro {
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  font-weight: var(--font-weight-medium);
  margin-bottom: calc(var(--spacing-unit) * 1);
  color: var(--gray-700); }

.quicklinks__link {
  margin-bottom: calc(var(--spacing-unit) * 1);
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: calc(var(--spacing-unit) * 1);
  justify-content: start; }
  .quicklinks__link a {
    text-decoration: none;
    transition: opacity 0.2s; }
    .quicklinks__link a:hover {
      opacity: 0.7; }

.quicklinks__link-arrow svg path {
  fill: var(--gray-400); }

.quicklinks--light .quicklinks__link {
  color: var(--white); }

.quicklinks--light .quicklinks__link-arrow svg path {
  fill: var(--gray-700); }

.quicklinks--horizontal {
  display: grid;
  grid-auto-flow: column;
  column-gap: calc(var(--spacing-unit) * 5); }
  .quicklinks--horizontal .quicklinks__intro {
    margin-bottom: 0;
    align-self: center; }
  .quicklinks--horizontal .quicklinks__links {
    display: grid;
    grid-auto-flow: column;
    column-gap: calc(var(--spacing-unit) * 2); }
  .quicklinks--horizontal .quicklinks__link {
    margin-bottom: 0; }

.radar {
  position: absolute;
  height: 1px;
  width: 1px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  --duration: 15;
  --size: 140;
  --background-color: rgba(0, 0, 0, 0.4);
  --border-color: rgba(0, 0, 0, 0.5); }

.radar__indicator--1,
.radar__indicator--2,
.radar__indicator--3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(var(--spacing-unit) * var(--size));
  height: calc(var(--spacing-unit) * var(--size)); }

.radar__indicator--1::before,
.radar__indicator--1::after,
.radar__indicator--2::before,
.radar__indicator--2::after,
.radar__indicator--3::before,
.radar__indicator--3::after {
  content: "";
  display: block;
  position: absolute;
  transform: translate(50%, 50%);
  height: 100%;
  width: 100%;
  border-radius: calc(var(--spacing-unit) * var(--size) / 2);
  background-color: var(--background-color);
  border: 4px solid var(--border-color);
  animation-name: radar;
  animation-duration: calc(var(--duration) * 1s);
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.035, 0.37, 0.05, 0.33); }

.radar__indicator--1::before {
  animation-delay: 0; }

.radar__indicator--1::after {
  animation-delay: calc(var(--duration) / 6 * 1s); }

.radar__indicator--2::before {
  animation-delay: calc(var(--duration) / 6 * 2s); }

.radar__indicator--2::after {
  animation-delay: calc(var(--duration) / 6 * 3s); }

.radar__indicator--3::before {
  animation-delay: calc(var(--duration) / 6 * 4s); }

.radar__indicator--3::after {
  animation-delay: calc(var(--duration) / 6 * 5s); }

@keyframes radar {
  0% {
    opacity: 1;
    transform: scale(0); }
  40% {
    opacity: 0; }
  100% {
    opacity: 0;
    transform: scale(1); } }

.signup-cta {
  grid-column: 2 / 14;
  align-self: start;
  max-width: calc(var(--spacing-unit) * 130);
  background-color: var(--gray-800);
  color: var(--white);
  padding: calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 6);
  overflow: hidden;
  position: relative; }
  @media (max-width: 1200px) {
    .signup-cta {
      grid-column: 2 / -9; } }
  @media (max-width: 900px) {
    .signup-cta {
      grid-column: 2 / -2;
      grid-row: 1; } }
  .signup-cta .key-visual {
    opacity: 0.2; }
  .signup-cta .signup-cta__headline {
    width: 100%;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.01em;
    font-weight: var(--font-weight-medium);
    margin-bottom: calc(var(--spacing-unit) * 3);
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: calc(var(--spacing-unit) * 2);
    position: relative;
    z-index: 2; }
    @media (max-width: 800px) {
      .signup-cta .signup-cta__headline {
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.02em; } }
    .signup-cta .signup-cta__headline::after {
      content: "";
      display: block;
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  .signup-cta .signup-cta__intro {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.005em;
    margin-bottom: 1.4em;
    max-width: 24em;
    position: relative;
    z-index: 2; }
    @media (max-width: 599px) {
      .signup-cta .signup-cta__intro {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.005em; } }
    .signup-cta .signup-cta__intro strong {
      font-weight: var(--font-weight-regukar); }
  .signup-cta .signup-cta__button {
    position: relative; }
    .signup-cta .signup-cta__button .button {
      position: relative;
      z-index: 2; }
  @media (max-width: 599px) {
    .signup-cta {
      padding: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 4); }
      .signup-cta .signup-cta__intro {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.009em;
        text-underline-offset: 1.5px; } }

.signup-cta--minimal {
  max-width: calc(var(--spacing-unit) * 110);
  background-color: var(--gray-150);
  color: var(--black);
  padding: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 4); }
  .signup-cta--minimal .key-visual {
    display: none; }
  .signup-cta--minimal .radar {
    display: none; }
  .signup-cta--minimal .signup-cta__headline {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.009em;
    text-underline-offset: 1.5px;
    font-weight: var(--font-weight-medium);
    margin-bottom: calc(var(--spacing-unit) * 1); }
    .signup-cta--minimal .signup-cta__headline::after {
      border-bottom-color: 1px solid rgba(0, 0, 0, 0.2); }
  .signup-cta--minimal .signup-cta__intro {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.009em;
    text-underline-offset: 1.5px;
    margin-bottom: 1.2em;
    max-width: 30em; }

.testimonials__cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: calc(var(--spacing-unit) * -5) calc(var(--spacing-unit) * -4); }
  @media (max-width: 599px) {
    .testimonials__cards {
      margin: calc(var(--spacing-unit) * -4); } }

.testimonials__card {
  width: 33%;
  min-width: calc(var(--spacing-unit) * 70);
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  color: var(--red);
  padding: calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 4); }
  @media (max-width: 800px) {
    .testimonials__card {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }
  @media (max-width: 1200px) {
    .testimonials__card {
      width: 50%;
      min-width: unset; } }
  @media (max-width: 900px) {
    .testimonials__card {
      width: 100%; } }
  @media (max-width: 599px) {
    .testimonials__card {
      padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 4); } }

.testimonials__quote {
  font-weight: var(--font-weight-light);
  max-width: 23em;
  text-align: center; }
  .testimonials__quote a {
    text-decoration-thickness: 2px;
    text-underline-offset: 0.07em; }
  .testimonials__quote::before {
    content: "“"; }
  .testimonials__quote::after {
    content: "”"; }
  @media (max-width: 599px) {
    .testimonials__quote {
      font-weight: var(--font-weight-regular); } }

.testimonials__credit {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.009em;
  text-underline-offset: 1.5px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 2);
  margin: 0 auto;
  width: max-content;
  margin-top: calc(var(--spacing-unit) * 3);
  color: var(--black); }
  @media (max-width: 599px) {
    .testimonials__credit {
      margin-top: calc(var(--spacing-unit) * 2); } }

.testimonials__face {
  display: block;
  max-height: calc(var(--spacing-unit) * 11);
  max-width: calc(var(--spacing-unit) * 11);
  object-fit: cover;
  border-radius: 50%;
  grid-column: 1; }
  @media (max-width: 900px) {
    .testimonials__face {
      max-height: calc(var(--spacing-unit) * 9);
      max-width: calc(var(--spacing-unit) * 9); } }

.testimonials__name {
  font-weight: var(--font-weight-medium);
  grid-column: 2; }

.about__intro {
  margin-top: calc(var(--spacing-unit) * 15);
  grid-template-rows: [headline] auto [quicklinks] auto [overhang] calc(var(--spacing-unit) * 8) [card] auto [cardOverhang] calc( var(--spacing-unit) * 8);
  margin-bottom: calc(var(--spacing-unit) * 25); }
  @media (max-width: 599px) {
    .about__intro {
      margin-top: calc(var(--spacing-unit) * 10);
      margin-bottom: calc(var(--spacing-unit) * 15);
      grid-template-rows: [headline] auto [quicklinks] auto [overhang] calc(var(--spacing-unit) * 4) [card] auto [cardOverhang] calc( var(--spacing-unit) * 4); } }

.about__headline {
  grid-column: 2 / -11;
  grid-row: headline;
  font-size: 80px;
  line-height: 76px;
  letter-spacing: -0.024em;
  word-spacing: 0.07em;
  font-weight: var(--font-weight-medium);
  margin-bottom: calc(var(--spacing-unit) * 12);
  z-index: 1; }
  @media (max-width: 1200px) {
    .about__headline {
      font-size: 74px;
      line-height: 72px;
      letter-spacing: -0.02em; } }
  @media (max-width: 900px) {
    .about__headline {
      font-size: 64px;
      line-height: 60px;
      letter-spacing: -0.02em; } }
  @media (max-width: 800px) {
    .about__headline {
      font-size: 45px;
      line-height: 47px;
      letter-spacing: -0.015em; } }
  @media (max-width: 1200px) {
    .about__headline {
      grid-column: 2 / -2; } }
  @media (max-width: 599px) {
    .about__headline {
      margin-bottom: calc(var(--spacing-unit) * 10); } }

.about__headline em {
  font-weight: var(--font-weight-bold);
  color: var(--red); }

.about__intro-card {
  z-index: 2;
  background-color: var(--gray-900);
  color: var(--white);
  grid-column: 2 / -12;
  grid-row: card / -1;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  padding: calc(var(--spacing-unit) * 6) calc(var(--spacing-unit) * 6); }
  @media (max-width: 800px) {
    .about__intro-card {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }
  .about__intro-card strong {
    color: var(--green); }
  @media (max-width: 1200px) {
    .about__intro-card {
      grid-column: 2 / -9; } }
  @media (max-width: 900px) {
    .about__intro-card {
      grid-column: 2 / -4; } }
  @media (max-width: 599px) {
    .about__intro-card {
      grid-column: 2 / -2;
      padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 3); } }

.about__key-visual {
  grid-column: 13 / -2;
  grid-row: overhang / cardOverhang;
  position: relative;
  z-index: -1;
  background-color: var(--green);
  overflow: hidden; }
  .about__key-visual .key-visual {
    opacity: 0.04; }
  @media (max-width: 1200px) {
    .about__key-visual {
      grid-column: 12 / -2; } }
  @media (max-width: 599px) {
    .about__key-visual {
      grid-column: 3 / -1; } }

.about__quicklinks {
  grid-column: 2 / -2;
  grid-row: quicklinks;
  justify-self: end;
  margin-bottom: calc(var(--spacing-unit) * 2); }
  @media (max-width: 900px) {
    .about__quicklinks {
      display: none; } }

.about__team {
  margin-bottom: calc(var(--spacing-unit) * 25); }
  @media (max-width: 599px) {
    .about__team {
      margin-bottom: calc(var(--spacing-unit) * 10); } }

.about__team-intro {
  grid-column: 2 / -2;
  margin-bottom: calc(var(--spacing-unit) * 14); }
  @media (max-width: 599px) {
    .about__team-intro {
      margin-bottom: calc(var(--spacing-unit) * 5); } }

.about__team-kicker {
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  font-weight: var(--font-weight-medium);
  display: table;
  margin-bottom: calc(var(--spacing-unit) * 1);
  color: var(--gray-600); }

.about__team-headline {
  font-size: 62px;
  line-height: 64px;
  letter-spacing: -0.025em;
  font-weight: var(--font-weight-medium); }
  @media (max-width: 800px) {
    .about__team-headline {
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -0.02em; } }

.about__team-member {
  grid-column: 2 / -2;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  position: relative; }

.about__team-member-info {
  border-top: 1px solid var(--black);
  padding-top: calc(var(--spacing-unit) * 2);
  padding-right: calc(var(--spacing-unit) * 10);
  padding-bottom: calc(var(--spacing-unit) * 8);
  hyphens: none;
  -webkit-hyphens: none;
  grid-column: 1 / 7; }
  @media (max-width: 1200px) {
    .about__team-member-info {
      grid-column: 9 / -1;
      padding-bottom: calc(var(--spacing-unit) * 5);
      margin-right: 0; } }
  @media (max-width: 599px) {
    .about__team-member-info {
      grid-column: 1 / -1;
      grid-row: 2; } }

.about__team-member-name {
  font-weight: var(--font-weight-medium); }

.about__team-member-role,
.about__team-member-contact {
  color: var(--gray-700); }

.about__team-member-bio {
  border-top: 1px solid var(--black);
  padding-top: calc(var(--spacing-unit) * 2);
  padding-bottom: calc(var(--spacing-unit) * 8);
  margin-right: calc(var(--spacing-unit) * 10);
  grid-column: 7 / 17; }
  @media (max-width: 1200px) {
    .about__team-member-bio {
      grid-column: 9 / -1;
      border-top: none;
      padding-top: calc(var(--spacing-unit) * 0);
      padding-bottom: calc(var(--spacing-unit) * 12);
      margin-right: 0; } }
  @media (max-width: 599px) {
    .about__team-member-bio {
      grid-column: 1 / -1;
      grid-row: 3; } }

.about__team-member-portrait {
  grid-column: 17 / -1; }
  @media (max-width: 1200px) {
    .about__team-member-portrait {
      grid-row: 1 / 3;
      align-self: top;
      grid-column: 1 / 9;
      width: 100%;
      padding-right: calc(var(--spacing-unit) * 5);
      padding-bottom: calc(var(--spacing-unit) * 12); } }
  @media (max-width: 599px) {
    .about__team-member-portrait {
      grid-column: 1 / -1;
      grid-row: 1;
      padding-bottom: calc(var(--spacing-unit) * 5);
      border-top: none;
      padding-right: 0;
      padding-top: 0; } }
  .about__team-member-portrait img {
    width: 100%;
    height: calc(var(--spacing-unit) * 50);
    object-fit: cover;
    filter: grayscale(1); }
    @media (max-width: 900px) {
      .about__team-member-portrait img {
        height: calc(var(--spacing-unit) * 35); } }
    @media (max-width: 599px) {
      .about__team-member-portrait img {
        height: 100%; } }

.about__team-member:nth-child(2n + 2) .about__team-member-portrait {
  position: absolute;
  bottom: calc(var(--spacing-unit) * -5);
  width: 70%;
  margin-left: 30%; }
  @media (max-width: 1200px) {
    .about__team-member:nth-child(2n + 2) .about__team-member-portrait {
      width: 100%;
      margin-left: 0;
      bottom: 0;
      position: relative; } }

.about__team-member:nth-child(2n + 1) .about__team-member-portrait {
  position: absolute;
  top: calc(var(--spacing-unit) * -4);
  width: 70%; }
  @media (max-width: 1200px) {
    .about__team-member:nth-child(2n + 1) .about__team-member-portrait {
      width: 100%;
      margin-left: 0;
      top: 0;
      position: relative; } }

.about__team-member:nth-child(4n + 1) .about__team-member-portrait {
  position: absolute;
  top: calc(var(--spacing-unit) * -4);
  width: 70%;
  margin-left: 10%; }
  @media (max-width: 1200px) {
    .about__team-member:nth-child(4n + 1) .about__team-member-portrait {
      width: 100%;
      margin-left: 0;
      top: 0;
      position: relative; } }

.about__team-advisors {
  margin-top: calc(var(--spacing-unit) * 10); }
  @media (max-width: 599px) {
    .about__team-advisors {
      margin-top: calc(var(--spacing-unit) * 5); } }

.about__team-advisor {
  margin-bottom: calc(var(--spacing-unit) * 2);
  display: block;
  text-decoration: none; }

.about__team-advisor-name {
  font-weight: var(--font-weight-medium); }

.about__team-advisor-role {
  color: var(--gray-700);
  margin-bottom: calc(var(--spacing-unit) * 1); }

.about__roots {
  grid-template-rows: calc(var(--spacing-unit) * 34) auto 1fr; }
  @media (max-width: 1200px) {
    .about__roots {
      grid-template-rows: auto auto auto; } }

.about__roots-card {
  grid-column: 2 / -6;
  grid-row: 1 / 3;
  background-color: var(--green);
  padding: calc(var(--spacing-unit) * 6) calc(var(--spacing-unit) * 7); }
  @media (max-width: 1200px) {
    .about__roots-card {
      grid-column: 2 / -4;
      padding-bottom: calc(var(--spacing-unit) * (7 + 10)); } }
  @media (max-width: 599px) {
    .about__roots-card {
      grid-column: 2 / -3;
      padding: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 3);
      padding-bottom: calc(var(--spacing-unit) * (6 + 10)); } }

.about__roots-kicker {
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  font-weight: var(--font-weight-medium);
  display: table;
  margin-bottom: calc(var(--spacing-unit) * 1);
  color: var(--black);
  opacity: 0.65; }

.about__roots-headline {
  font-size: 62px;
  line-height: 64px;
  letter-spacing: -0.025em;
  font-weight: var(--font-weight-medium);
  margin-bottom: calc(var(--spacing-unit) * 8); }
  @media (max-width: 800px) {
    .about__roots-headline {
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -0.02em; } }

.about__roots-headline em {
  color: var(--white); }

.about__roots-story {
  max-width: 40%; }
  @media (max-width: 1200px) {
    .about__roots-story {
      max-width: 100%;
      max-width: 32em; } }

.about__roots-logos {
  grid-row: 2 / 4;
  grid-column: 11 / -2;
  background-color: var(--gray-150);
  padding: calc(var(--spacing-unit) * 8) calc(var(--spacing-unit) * 7);
  text-align: center; }
  @media (max-width: 1200px) {
    .about__roots-logos {
      grid-row: 3;
      grid-column: 6 / -2;
      margin-top: calc(var(--spacing-unit) * -10); } }
  @media (max-width: 599px) {
    .about__roots-logos {
      grid-column: 3 / -2;
      padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 3); } }

.about__roots-logo-link {
  display: inline-block;
  margin: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 4); }
  @media (max-width: 599px) {
    .about__roots-logo-link {
      margin: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 3); } }

.about__roots-logo-image {
  height: calc(var(--spacing-unit) * 10); }
  @media (max-width: 599px) {
    .about__roots-logo-image {
      height: calc(var(--spacing-unit) * 7);
      max-width: 100%;
      object-fit: contain; } }

.benefits__intro {
  margin-top: calc(var(--spacing-unit) * 10);
  grid-template-rows: calc(var(--spacing-unit) * 15) auto calc(var(--spacing-unit) * 8);
  margin-bottom: calc(var(--spacing-unit) * 20); }
  @media (max-width: 599px) {
    .benefits__intro {
      margin-bottom: calc(var(--spacing-unit) * 15);
      grid-template-rows: calc(var(--spacing-unit) * 15) auto 0; } }

.benefits__intro-card {
  grid-column: 2 / -13;
  grid-row: 1 / 3;
  background-color: var(--green);
  padding: calc(var(--spacing-unit) * 7) calc(var(--spacing-unit) * 7); }
  @media (max-width: 1200px) {
    .benefits__intro-card {
      grid-column: 2 / -8; } }
  @media (max-width: 900px) {
    .benefits__intro-card {
      grid-column: 2 / -4; } }
  @media (max-width: 599px) {
    .benefits__intro-card {
      padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 4);
      grid-column: 2 / -2; } }

.benefits__headline {
  font-size: 80px;
  line-height: 76px;
  letter-spacing: -0.024em;
  word-spacing: 0.07em;
  font-weight: var(--font-weight-medium);
  margin-bottom: calc(var(--spacing-unit) * 10);
  color: var(--white); }
  @media (max-width: 1200px) {
    .benefits__headline {
      font-size: 74px;
      line-height: 72px;
      letter-spacing: -0.02em; } }
  @media (max-width: 900px) {
    .benefits__headline {
      font-size: 64px;
      line-height: 60px;
      letter-spacing: -0.02em; } }
  @media (max-width: 800px) {
    .benefits__headline {
      font-size: 45px;
      line-height: 47px;
      letter-spacing: -0.015em; } }
  @media (max-width: 599px) {
    .benefits__headline {
      margin-bottom: calc(var(--spacing-unit) * 4); } }

.benefits__headline em {
  font-weight: var(--font-weight-bold);
  color: var(--red); }

.benefits__intro-text {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  max-width: 26em; }
  @media (max-width: 800px) {
    .benefits__intro-text {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }

.benefits__intro-key-visual {
  grid-row: 2 / 4;
  grid-column: 5 / -2;
  position: relative;
  z-index: -1;
  background-color: var(--gray-150);
  overflow: hidden; }
  .benefits__intro-key-visual .key-visual {
    opacity: 0.05; }
  @media (max-width: 1200px) {
    .benefits__intro-key-visual {
      grid-column: 8 / -2; } }
  @media (max-width: 900px) {
    .benefits__intro-key-visual {
      grid-column: 5 / -2; } }
  @media (max-width: 599px) {
    .benefits__intro-key-visual {
      display: none; } }

.benefits__benefits {
  margin-bottom: calc(var(--spacing-unit) * 30); }
  @media (max-width: 599px) {
    .benefits__benefits {
      margin-bottom: calc(var(--spacing-unit) * 10); } }

.benefits__benefits-list {
  grid-row: 2;
  grid-column: 3 / -3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: calc(var(--spacing-unit) * 15);
  row-gap: calc(var(--spacing-unit) * 20);
  align-items: start; }
  @media (max-width: 1200px) {
    .benefits__benefits-list {
      grid-column: 2 / -2;
      margin-left: calc(var(--spacing-unit) * 0); } }
  @media (max-width: 900px) {
    .benefits__benefits-list {
      column-gap: calc(var(--spacing-unit) * 10);
      row-gap: calc(var(--spacing-unit) * 12); } }
  @media (max-width: 700px) {
    .benefits__benefits-list {
      grid-template-columns: 1fr;
      row-gap: calc(var(--spacing-unit) * 8); } }

.benefits__benefit-icon {
  width: calc(var(--spacing-unit) * 14);
  display: block;
  margin-bottom: calc(var(--spacing-unit) * 3); }
  @media (max-width: 900px) {
    .benefits__benefit-icon {
      width: calc(var(--spacing-unit) * 12);
      margin-bottom: calc(var(--spacing-unit) * 2); } }

.benefits__benefit-icon-image {
  max-width: 100%;
  display: block; }

.benefits__benefit-title {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  max-width: 16em; }
  @media (max-width: 800px) {
    .benefits__benefit-title {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }
  @media (max-width: 1200px) {
    .benefits__benefit-title {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: -0.005em; } }
  @media (max-width: 1200px) and (max-width: 599px) {
    .benefits__benefit-title {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  @media (max-width: 599px) {
    .benefits__benefit-title {
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.01em; } }
  @media (max-width: 599px) and (max-width: 800px) {
    .benefits__benefit-title {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }

.benefits__benefit-title-underlined {
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.015em;
  font-weight: var(--font-weight-medium);
  color: var(--red);
  margin-bottom: 0.2em; }
  @media (max-width: 800px) {
    .benefits__benefit-title-underlined {
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.015em; } }
  @media (max-width: 1200px) {
    .benefits__benefit-title-underlined {
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.01em; } }
  @media (max-width: 1200px) and (max-width: 800px) {
    .benefits__benefit-title-underlined {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }
  @media (max-width: 599px) {
    .benefits__benefit-title-underlined {
      margin-bottom: 0.3em; } }

.benefits__benefit-text {
  margin-top: calc(var(--spacing-unit) * 6);
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.015em;
  color: var(--gray-800);
  max-width: 22em;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em; }
  @media (max-width: 800px) {
    .benefits__benefit-text {
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.015em; } }
  @media (max-width: 599px) {
    .benefits__benefit-text {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  @media (max-width: 900px) {
    .benefits__benefit-text {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.009em;
      text-underline-offset: 1.5px;
      max-width: 20em;
      margin-top: calc(var(--spacing-unit) * 4); } }
  .benefits__benefit-text ul {
    margin: 0;
    padding: 0; }
    @media (max-width: 900px) {
      .benefits__benefit-text ul {
        padding-left: 1.3em; } }
  .benefits__benefit-text li {
    margin-top: calc(var(--spacing-unit) * 1.5); }
    .benefits__benefit-text li::marker {
      color: var(--green);
      content: "—  "; }
  @media (max-width: 599px) {
    .benefits__benefit-text {
      margin-top: calc(var(--spacing-unit) * 3);
      margin-bottom: calc(var(--spacing-unit) * 1); } }

.blog-article {
  margin-top: calc(var(--spacing-unit) * 15); }
  @media (max-width: 599px) {
    .blog-article {
      margin-top: calc(var(--spacing-unit) * 10); } }

.blog-article__author {
  grid-column: 2 / 9;
  margin-right: calc(var(--spacing-unit) * 5); }
  @media (max-width: 1200px) {
    .blog-article__author {
      grid-column: 2 / -2;
      grid-row: 2;
      margin-right: 0;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      column-gap: calc(var(--spacing-unit) * 2);
      margin-top: calc(var(--spacing-unit) * 5); } }

.blog-article__author-portrait {
  width: calc(var(--spacing-unit) * 22);
  height: calc(var(--spacing-unit) * 22);
  object-fit: cover;
  border-radius: calc(var(--spacing-unit) * 15);
  margin-bottom: calc(var(--spacing-unit) * 1);
  filter: grayscale(1) brightness(0.9); }
  @media (max-width: 1200px) {
    .blog-article__author-portrait {
      width: calc(var(--spacing-unit) * 9);
      height: calc(var(--spacing-unit) * 9);
      margin-bottom: 0; } }

.blog-article__author-info {
  min-width: calc(var(--spacing-unit) * 22);
  width: max-content;
  max-width: 100%;
  text-align: center; }
  @media (max-width: 1200px) {
    .blog-article__author-info {
      text-align: left; } }

.blog-article__author-name {
  font-weight: var(--font-weight-medium); }

.blog-article__author-role {
  color: var(--gray-700);
  max-width: 15em; }
  @media (max-width: 1200px) {
    .blog-article__author-role {
      max-width: 100%; } }

.blog-article__headline {
  grid-column: 9 / -2;
  grid-row: 1;
  font-size: 80px;
  line-height: 76px;
  letter-spacing: -0.024em;
  word-spacing: 0.07em;
  font-weight: var(--font-weight-medium);
  align-self: center; }
  @media (max-width: 1200px) {
    .blog-article__headline {
      font-size: 74px;
      line-height: 72px;
      letter-spacing: -0.02em; } }
  @media (max-width: 900px) {
    .blog-article__headline {
      font-size: 64px;
      line-height: 60px;
      letter-spacing: -0.02em; } }
  @media (max-width: 800px) {
    .blog-article__headline {
      font-size: 45px;
      line-height: 47px;
      letter-spacing: -0.015em; } }
  @media (max-width: 1200px) {
    .blog-article__headline {
      grid-column: 2 / -2; } }

.blog-article__headline em {
  font-weight: var(--font-weight-bold);
  color: var(--red); }

.blog-article--type-campaign .blog-article__headline {
  grid-column: 2 / -2; }

.blog-article__hero {
  display: block;
  grid-column: 2 / -2;
  width: 100%;
  margin-top: calc(var(--spacing-unit) * 10);
  margin-bottom: calc(var(--spacing-unit) * 10); }
  @media (max-width: 599px) {
    .blog-article__hero {
      margin-top: calc(var(--spacing-unit) * 5);
      margin-bottom: calc(var(--spacing-unit) * 3); } }

.blog-article__published {
  grid-column: 2 / 9;
  border-top: 1px solid var(--black);
  margin-right: calc(var(--spacing-unit) * 5);
  padding-top: calc(var(--spacing-unit) * 2);
  margin-top: calc(var(--spacing-unit) * 2);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.009em;
  text-underline-offset: 1.5px;
  font-weight: var(--font-weight-medium);
  color: var(--red); }
  @media (max-width: 900px) {
    .blog-article__published {
      grid-column: 2 / -2; } }

.blog-article__headline + .blog-article__published {
  margin-top: calc(var(--spacing-unit) * 18); }
  @media (max-width: 599px) {
    .blog-article__headline + .blog-article__published {
      margin-top: calc(var(--spacing-unit) * 6); } }

.blog-article__headline + .blog-article__published + .blog-article__intro {
  margin-top: calc(var(--spacing-unit) * 16); }
  @media (max-width: 900px) {
    .blog-article__headline + .blog-article__published + .blog-article__intro {
      margin-top: calc(var(--spacing-unit) * 10); } }

@media (max-width: 900px) {
  .blog-article__headline + .blog-article__hero + .blog-article__published + .blog-article__intro {
    margin-top: calc(var(--spacing-unit) * 10); } }

@media (max-width: 599px) {
  .blog-article__headline + .blog-article__hero + .blog-article__published + .blog-article__intro {
    margin-top: calc(var(--spacing-unit) * 7); } }

.blog-article__headline + .blog-article__published + .blog-article__body {
  margin-top: calc(var(--spacing-unit) * 16); }
  @media (max-width: 900px) {
    .blog-article__headline + .blog-article__published + .blog-article__body {
      margin-top: calc(var(--spacing-unit) * 10); } }

@media (max-width: 900px) {
  .blog-article__headline + .blog-article__hero + .blog-article__published + .blog-article__body {
    margin-top: calc(var(--spacing-unit) * 10); } }

.blog-article__intro {
  grid-column: 9 / -2;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  margin-bottom: calc(var(--spacing-unit) * 10);
  max-width: 25em; }
  @media (max-width: 800px) {
    .blog-article__intro {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }
  @media (max-width: 900px) {
    .blog-article__intro {
      grid-column: 2 / -2; } }
  @media (max-width: 599px) {
    .blog-article__intro {
      margin-bottom: calc(var(--spacing-unit) * 6); } }

.blog-article__body {
  grid-column: 9 / -2; }
  @media (max-width: 900px) {
    .blog-article__body {
      grid-column: 2 / -2; } }
  .blog-article__body > :first-child {
    margin-top: 0 !important; }
  .blog-article__body > :last-child {
    margin-bottom: 0; }
  .blog-article__body p,
  .blog-article__body li {
    max-width: 40em; }
  .blog-article__body u {
    text-decoration: none;
    color: var(--gray-600); }
  .blog-article__body figure {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    margin: 0;
    margin-top: calc(var(--spacing-unit) * 8);
    margin-bottom: calc(var(--spacing-unit) * 8);
    margin-left: calc((100% / 17) * -7); }
    @media (max-width: 900px) {
      .blog-article__body figure {
        margin-left: 0;
        grid-template-columns: 1fr;
        justify-items: start; } }
    .blog-article__body figure img {
      display: block;
      grid-column: 8 / -1;
      width: 100%;
      grid-row: 1; }
      @media (max-width: 900px) {
        .blog-article__body figure img {
          grid-column: 1; } }
    .blog-article__body figure figcaption {
      grid-column: 1 / 8;
      grid-row: 1;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.009em;
      text-underline-offset: 1.5px;
      border-top: 1px solid var(--black);
      margin-right: calc(var(--spacing-unit) * 5);
      padding-top: calc(var(--spacing-unit) * 2); }
      @media (max-width: 900px) {
        .blog-article__body figure figcaption {
          grid-row: 2;
          margin-right: calc(var(--spacing-unit) * 0);
          border-top: none;
          color: var(--gray-700); } }
  .blog-article__body figure.figure--full-width {
    position: relative; }
    .blog-article__body figure.figure--full-width img {
      grid-column: 1 / -1; }
    .blog-article__body figure.figure--full-width figcaption {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translateY(calc(100% + var(--spacing-unit) * 3)); }
  .blog-article__body blockquote.quote--block {
    margin-left: calc(var(--spacing-unit) * 4);
    padding-left: calc(var(--spacing-unit) * 2.5);
    margin-top: calc(var(--spacing-unit) * 5);
    margin-bottom: calc(var(--spacing-unit) * 5);
    color: var(--gray-800);
    border-left: 2px solid var(--green); }
    .blog-article__body blockquote.quote--block p {
      max-width: 33em; }
  .blog-article__body blockquote.quote--highlight {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    margin: 0;
    margin-top: calc(var(--spacing-unit) * 8);
    margin-bottom: calc(var(--spacing-unit) * 8);
    margin-left: calc((100% / 17) * -7);
    color: var(--gray-600); }
    @media (max-width: 900px) {
      .blog-article__body blockquote.quote--highlight {
        margin-left: 0;
        grid-template-columns: 1fr;
        justify-items: start; } }
    .blog-article__body blockquote.quote--highlight div {
      grid-column: 8 / -1;
      width: 100%;
      grid-row: 1;
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.01em;
      font-weight: var(--font-weight-light);
      max-width: 23em;
      color: var(--red);
      hanging-punctuation: first; }
      @media (max-width: 800px) {
        .blog-article__body blockquote.quote--highlight div {
          font-size: 22px;
          line-height: 28px;
          letter-spacing: -0.02em; } }
      .blog-article__body blockquote.quote--highlight div a {
        text-decoration-thickness: 2px;
        text-underline-offset: 2px; }
      @media (max-width: 900px) {
        .blog-article__body blockquote.quote--highlight div {
          grid-column: 1; } }
      .blog-article__body blockquote.quote--highlight div::before {
        content: "“"; }
      .blog-article__body blockquote.quote--highlight div::after {
        content: "”"; }
    .blog-article__body blockquote.quote--highlight cite {
      grid-column: 1 / 8;
      grid-row: 1;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.009em;
      text-underline-offset: 1.5px;
      font-style: normal;
      border-top: 1px solid var(--gray-500);
      margin-top: calc(var(--spacing-unit) * 1.9);
      margin-right: calc(var(--spacing-unit) * 5);
      padding-top: calc(var(--spacing-unit) * 2); }
      @media (max-width: 900px) {
        .blog-article__body blockquote.quote--highlight cite {
          grid-column: 1;
          grid-row: 2;
          border-top: none;
          margin-right: none;
          margin-top: 0; } }
  .blog-article__body h2 {
    margin: 0;
    margin-top: calc(var(--spacing-unit) * 15);
    margin-bottom: calc(var(--spacing-unit) * 8);
    font-size: 50px;
    line-height: 54px;
    letter-spacing: -0.02em;
    max-width: 16em;
    hyphens: none;
    -webkit-hyphens: none;
    font-weight: var(--font-weight-medium); }
    @media (max-width: 800px) {
      .blog-article__body h2 {
        font-size: 32px;
        line-height: 35px;
        letter-spacing: -0.02em; } }
    @media (max-width: 599px) {
      .blog-article__body h2 {
        margin-top: calc(var(--spacing-unit) * 10);
        margin-bottom: calc(var(--spacing-unit) * 4); } }
  .blog-article__body h3 {
    margin: 0;
    margin-top: calc(var(--spacing-unit) * 12);
    margin-bottom: calc(var(--spacing-unit) * 5);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.01em;
    font-weight: var(--font-weight-medium); }
    @media (max-width: 800px) {
      .blog-article__body h3 {
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.02em; } }
    @media (max-width: 599px) {
      .blog-article__body h3 {
        margin-top: calc(var(--spacing-unit) * 7);
        margin-bottom: calc(var(--spacing-unit) * 3); } }
  .blog-article__body h4 {
    margin: 0;
    margin-top: calc(var(--spacing-unit) * 5);
    margin-bottom: calc(var(--spacing-unit) * 1);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.009em;
    text-underline-offset: 1.5px;
    font-weight: var(--font-weight-medium); }
  .blog-article__body ul,
  .blog-article__body ol {
    margin-top: calc(var(--spacing-unit) * 3);
    margin-bottom: calc(var(--spacing-unit) * 3);
    padding-left: 0; }
    @media (max-width: 900px) {
      .blog-article__body ul,
      .blog-article__body ol {
        padding-left: 1.2em; } }
  .blog-article__body li {
    margin-bottom: calc(var(--spacing-unit) * 1); }
    .blog-article__body li:last-child {
      margin-bottom: 0; }
    .blog-article__body li::marker {
      color: var(--green); }
  .blog-article__body .signup-cta {
    margin-top: calc(var(--spacing-unit) * 14);
    margin-bottom: calc(var(--spacing-unit) * 14); }
    @media (max-width: 599px) {
      .blog-article__body .signup-cta {
        margin-top: calc(var(--spacing-unit) * 8);
        margin-bottom: calc(var(--spacing-unit) * 8); } }
  .blog-article__body .signup-cta--minimal {
    margin-top: calc(var(--spacing-unit) * 6);
    margin-bottom: calc(var(--spacing-unit) * 6); }
  .blog-article__body .twitter-embed,
  .blog-article__body .loom-embed,
  .blog-article__body .vimeo-embed,
  .blog-article__body .youtube-embed,
  .blog-article__body .soundcloud-embed {
    margin-top: calc(var(--spacing-unit) * 8);
    margin-bottom: calc(var(--spacing-unit) * 8); }
    @media (max-width: 599px) {
      .blog-article__body .twitter-embed,
      .blog-article__body .loom-embed,
      .blog-article__body .vimeo-embed,
      .blog-article__body .youtube-embed,
      .blog-article__body .soundcloud-embed {
        margin-top: calc(var(--spacing-unit) * 5);
        margin-bottom: calc(var(--spacing-unit) * 5); } }
  .blog-article__body .transcript {
    font-size: 13.5px;
    line-height: 19px;
    letter-spacing: 0.02em; }
  .blog-article__body .cta {
    margin-top: calc(var(--spacing-unit) * 12);
    margin-bottom: calc(var(--spacing-unit) * 12);
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 40em;
    border-top: 1px solid var(--gray-300);
    border-bottom: 1px solid var(--gray-300);
    padding: calc(var(--spacing-unit) * 6) 0; }
    .blog-article__body .cta__link {
      text-decoration: none; }
  .blog-article__body .testimonials {
    margin: 0;
    margin-top: calc(var(--spacing-unit) * 12);
    margin-bottom: calc(var(--spacing-unit) * 12);
    margin-left: calc((100% / 17) * -7); }
    @media (max-width: 900px) {
      .blog-article__body .testimonials {
        margin-left: 0;
        grid-template-columns: 1fr;
        justify-items: start; } }

.blog-article--base-font-size-gamma {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em; }
  @media (max-width: 599px) {
    .blog-article--base-font-size-gamma {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  .blog-article--base-font-size-gamma .blog-article__body p {
    max-width: 30em; }
  .blog-article--base-font-size-gamma .blog-article__body li {
    max-width: 26em; }

.blog-article--base-font-size-delta {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em; }
  @media (max-width: 800px) {
    .blog-article--base-font-size-delta {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }
  .blog-article--base-font-size-delta .blog-article__body p {
    max-width: 27em; }
  .blog-article--base-font-size-delta .blog-article__body li {
    max-width: 23em;
    margin-bottom: calc(var(--spacing-unit) * 2); }

.blog-article__tags {
  grid-column: 2 / 9;
  border-top: 1px solid var(--black);
  padding-top: calc(var(--spacing-unit) * 2);
  margin-right: calc(var(--spacing-unit) * 5);
  margin-top: calc(var(--spacing-unit) * 25);
  font-weight: var(--font-weight-medium); }
  .blog-article__tags--full {
    grid-column: 2 / -2; }
  @media (max-width: 1200px) {
    .blog-article__tags {
      grid-column: 2 / -2;
      margin-top: calc(var(--spacing-unit) * 15);
      margin-right: 0; } }
  @media (max-width: 599px) {
    .blog-article__tags {
      margin-top: calc(var(--spacing-unit) * 10); } }

.blog-article__tags-list {
  font-weight: var(--font-weight-regular); }

.blog-article__recent-posts {
  grid-column: 9 / -2;
  border-top: 1px solid var(--black);
  padding-top: calc(var(--spacing-unit) * 2);
  margin-top: calc(var(--spacing-unit) * 25);
  display: grid;
  grid-template-columns: 1fr auto; }
  .blog-article__recent-posts--full {
    grid-column: 2 / -2; }
    .blog-article__recent-posts--full .blog-article__recent-posts-grid {
      grid-template-columns: 1fr 1fr 1fr; }
      .blog-article__recent-posts--full .blog-article__recent-posts-grid > :nth-child(3) {
        display: unset; }
  @media (max-width: 1200px) {
    .blog-article__recent-posts {
      grid-column: 2 / -2;
      margin-top: calc(var(--spacing-unit) * 10); }
      .blog-article__recent-posts--full {
        margin-top: calc(var(--spacing-unit) * 15); }
        .blog-article__recent-posts--full .blog-article__recent-posts-grid {
          grid-template-columns: 1fr 1fr; }
          .blog-article__recent-posts--full .blog-article__recent-posts-grid > :nth-child(3) {
            display: none; } }
  @media (max-width: 599px) {
    .blog-article__recent-posts--full .blog-article__recent-posts-grid {
      grid-template-columns: 1fr; } }

.blog-article__recent-posts-title {
  font-weight: var(--font-weight-medium);
  grid-row: 1;
  grid-column: 1; }

.blog-article__recent-posts-grid {
  grid-row: 2;
  grid-column: 1 / -1;
  display: grid;
  margin-top: calc(var(--spacing-unit) * 5);
  grid-template-columns: 1fr 1fr;
  column-gap: calc(var(--spacing-unit) * 6); }
  .blog-article__recent-posts-grid > :nth-child(3) {
    display: none; }
  @media (max-width: 599px) {
    .blog-article__recent-posts-grid {
      grid-auto-flow: row;
      grid-template-columns: 1fr;
      row-gap: calc(var(--spacing-unit) * 8); } }

.blog-article__article-link {
  text-decoration: none;
  align-self: start; }

.blog-article__article-published {
  font-weight: var(--font-weight-medium);
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: var(--red);
  margin-bottom: calc(var(--spacing-unit) * 0.5); }

.blog-article__article-headline {
  font-weight: var(--font-weight-medium);
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  margin-bottom: calc(var(--spacing-unit) * 3);
  hyphens: none;
  -webkit-hyphens: none; }
  @media (max-width: 599px) {
    .blog-article__article-headline {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }

.blog-article__article-hero {
  margin-bottom: calc(var(--spacing-unit) * 2); }

.blog-article__article-hero img {
  width: 100%;
  max-height: calc(var(--spacing-unit) * 44);
  object-fit: cover;
  overflow: hidden; }

.blog-article__article-keep-reading {
  font-weight: var(--font-weight-medium);
  text-decoration: underline;
  margin-top: calc(var(--spacing-unit) * 2); }

.blog__article-list {
  margin-top: calc(var(--spacing-unit) * 15);
  grid-column: 2 / -2;
  display: flex;
  flex-wrap: wrap;
  --gap: calc(var(--spacing-unit) * 10);
  column-gap: var(--gap);
  row-gap: calc(var(--spacing-unit) * 20); }
  @media (max-width: 599px) {
    .blog__article-list {
      row-gap: calc(var(--spacing-unit) * 12); } }

.blog__article {
  width: calc((100% - var(--gap) * 2) / 3);
  text-decoration: none; }
  @media (max-width: 900px) {
    .blog__article {
      width: 40%;
      flex-grow: 1;
      min-width: calc(var(--spacing-unit) * 60); } }
  @media (max-width: 599px) {
    .blog__article {
      width: 100%;
      min-width: unset; } }
  @media (min-width: 900px) {
    .blog__article:nth-child(6), .blog__article:nth-child(7), .blog__article:nth-child(1), .blog__article:nth-child(2), .blog__article:nth-child(11), .blog__article:nth-child(12) {
      width: calc((100% - var(--gap) * 1) / 2); }
      .blog__article:nth-child(6) .blog__article-headline, .blog__article:nth-child(7) .blog__article-headline, .blog__article:nth-child(1) .blog__article-headline, .blog__article:nth-child(2) .blog__article-headline, .blog__article:nth-child(11) .blog__article-headline, .blog__article:nth-child(12) .blog__article-headline {
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -0.01em;
        margin-bottom: calc(var(--spacing-unit) * 3); } }
    @media (min-width: 900px) and (max-width: 800px) {
      .blog__article:nth-child(6) .blog__article-headline, .blog__article:nth-child(7) .blog__article-headline, .blog__article:nth-child(1) .blog__article-headline, .blog__article:nth-child(2) .blog__article-headline, .blog__article:nth-child(11) .blog__article-headline, .blog__article:nth-child(12) .blog__article-headline {
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.02em; } }
  @media (min-width: 900px) {
      .blog__article:nth-child(6) .blog__article-hero img, .blog__article:nth-child(7) .blog__article-hero img, .blog__article:nth-child(1) .blog__article-hero img, .blog__article:nth-child(2) .blog__article-hero img, .blog__article:nth-child(11) .blog__article-hero img, .blog__article:nth-child(12) .blog__article-hero img {
        max-height: unset; }
    .blog__article:nth-child(6), .blog__article:nth-child(2), .blog__article:nth-child(11) {
      margin-top: 15%; } }
  @media (min-width: 1200px) {
    .blog__article:nth-child(1), .blog__article:nth-child(12) {
      width: calc(((100% - var(--gap) * 2) / 3) * 2 + var(--gap)); }
      .blog__article:nth-child(1) .blog__article-headline, .blog__article:nth-child(12) .blog__article-headline {
        font-size: 50px;
        line-height: 54px;
        letter-spacing: -0.02em;
        margin-bottom: calc(var(--spacing-unit) * 4); } }
    @media (min-width: 1200px) and (max-width: 800px) {
      .blog__article:nth-child(1) .blog__article-headline, .blog__article:nth-child(12) .blog__article-headline {
        font-size: 32px;
        line-height: 35px;
        letter-spacing: -0.02em; } }
  @media (min-width: 1200px) {
      .blog__article:nth-child(1) .blog__article-hero, .blog__article:nth-child(12) .blog__article-hero {
        margin-bottom: calc(var(--spacing-unit) * 3); }
      .blog__article:nth-child(1) .blog__article-hero img, .blog__article:nth-child(12) .blog__article-hero img {
        max-height: unset; }
    .blog__article:nth-child(2), .blog__article:nth-child(11) {
      width: calc((100% - var(--gap) * 2) / 3);
      margin-top: 24%;
      align-self: flex-end; }
      .blog__article:nth-child(2) .blog__article-headline, .blog__article:nth-child(11) .blog__article-headline {
        font-size: 22px;
        line-height: 30px;
        letter-spacing: -0.005em;
        margin-bottom: calc(var(--spacing-unit) * 3); } }
    @media (min-width: 1200px) and (max-width: 599px) {
      .blog__article:nth-child(2) .blog__article-headline, .blog__article:nth-child(11) .blog__article-headline {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.005em; } }
  @media (min-width: 1200px) {
      .blog__article:nth-child(2) .blog__article-hero img, .blog__article:nth-child(11) .blog__article-hero img {
        max-height: calc(var(--spacing-unit) * 44); } }

.blog__article-hero {
  margin-bottom: calc(var(--spacing-unit) * 2); }

.blog__article-hero img {
  width: 100%;
  max-height: calc(var(--spacing-unit) * 44);
  object-fit: cover;
  overflow: hidden;
  transition: filter 0.7s; }

.blog__article-published {
  font-weight: var(--font-weight-medium);
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: var(--red);
  margin-bottom: calc(var(--spacing-unit) * 0.5); }

.blog__article-headline {
  font-weight: var(--font-weight-medium);
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  margin-bottom: calc(var(--spacing-unit) * 3);
  hyphens: none;
  -webkit-hyphens: none; }
  @media (max-width: 599px) {
    .blog__article-headline {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }

.blog__article-keep-reading {
  font-weight: var(--font-weight-medium);
  text-decoration: underline;
  margin-top: calc(var(--spacing-unit) * 2);
  transition: all 0.3s; }

.blog__article:hover .blog__article-keep-reading {
  color: var(--red); }

.contact__headline {
  grid-column: 2 / -2;
  grid-row: 1;
  font-size: 80px;
  line-height: 76px;
  letter-spacing: -0.024em;
  word-spacing: 0.07em;
  font-weight: var(--font-weight-medium);
  margin-top: calc(var(--spacing-unit) * 15);
  margin-bottom: calc(var(--spacing-unit) * 6);
  max-width: 14em; }
  @media (max-width: 1200px) {
    .contact__headline {
      font-size: 74px;
      line-height: 72px;
      letter-spacing: -0.02em; } }
  @media (max-width: 900px) {
    .contact__headline {
      font-size: 64px;
      line-height: 60px;
      letter-spacing: -0.02em; } }
  @media (max-width: 800px) {
    .contact__headline {
      font-size: 45px;
      line-height: 47px;
      letter-spacing: -0.015em; } }
  @media (max-width: 1200px) {
    .contact__headline {
      grid-column: 2 / -2; } }
  @media (max-width: 599px) {
    .contact__headline {
      margin-top: calc(var(--spacing-unit) * 10); } }

.contact__headline em {
  font-weight: var(--font-weight-bold);
  color: var(--red); }

.contact__links {
  grid-column: 2 / -2;
  font-size: 80px;
  line-height: 76px;
  letter-spacing: -0.024em;
  word-spacing: 0.07em;
  font-weight: var(--font-weight-medium);
  color: var(--green);
  margin-bottom: calc(var(--spacing-unit) * 20); }
  @media (max-width: 1200px) {
    .contact__links {
      font-size: 74px;
      line-height: 72px;
      letter-spacing: -0.02em; } }
  @media (max-width: 900px) {
    .contact__links {
      font-size: 64px;
      line-height: 60px;
      letter-spacing: -0.02em; } }
  @media (max-width: 800px) {
    .contact__links {
      font-size: 45px;
      line-height: 47px;
      letter-spacing: -0.015em; } }
  @media (max-width: 1200px) {
    .contact__links {
      grid-column: 2 / -2; } }
  @media (max-width: 599px) {
    .contact__links {
      margin-bottom: calc(var(--spacing-unit) * 15); } }

.contact__link {
  display: inline-block; }

.contact__read-more {
  grid-column: 2 / -6; }
  @media (max-width: 1200px) {
    .contact__read-more {
      grid-column: 2 / -2; } }

.error__headline {
  grid-column: 4 / -4;
  font-size: 80px;
  line-height: 76px;
  letter-spacing: -0.024em;
  word-spacing: 0.07em;
  font-weight: var(--font-weight-medium);
  margin-top: calc(var(--spacing-unit) * 12);
  margin-bottom: calc(var(--spacing-unit) * 20); }
  @media (max-width: 1200px) {
    .error__headline {
      font-size: 74px;
      line-height: 72px;
      letter-spacing: -0.02em; } }
  @media (max-width: 900px) {
    .error__headline {
      font-size: 64px;
      line-height: 60px;
      letter-spacing: -0.02em; } }
  @media (max-width: 800px) {
    .error__headline {
      font-size: 45px;
      line-height: 47px;
      letter-spacing: -0.015em; } }
  @media (max-width: 1200px) {
    .error__headline {
      grid-column: 2 / -2; } }

.error__read-more {
  grid-column: 4 / -4; }
  @media (max-width: 1200px) {
    .error__read-more {
      grid-column: 2 / -2; } }

.features__intro {
  margin-top: calc(var(--spacing-unit) * 15);
  margin-bottom: calc(var(--spacing-unit) * 33); }
  @media (max-width: 599px) {
    .features__intro {
      margin-top: calc(var(--spacing-unit) * 10);
      margin-bottom: calc(var(--spacing-unit) * 15); } }

.features__headline {
  grid-column: 8 / -2;
  font-size: 80px;
  line-height: 76px;
  letter-spacing: -0.024em;
  word-spacing: 0.07em;
  font-weight: var(--font-weight-medium);
  margin-bottom: calc(var(--spacing-unit) * 12);
  z-index: 1;
  hyphens: manual;
  -webkit-hyphens: manual; }
  @media (max-width: 1200px) {
    .features__headline {
      font-size: 74px;
      line-height: 72px;
      letter-spacing: -0.02em; } }
  @media (max-width: 900px) {
    .features__headline {
      font-size: 64px;
      line-height: 60px;
      letter-spacing: -0.02em; } }
  @media (max-width: 800px) {
    .features__headline {
      font-size: 45px;
      line-height: 47px;
      letter-spacing: -0.015em; } }
  .features__headline strong {
    color: var(--red); }
  @media (max-width: 1200px) {
    .features__headline {
      grid-column: 2 / -2; } }
  @media (max-width: 599px) {
    .features__headline {
      margin-bottom: calc(var(--spacing-unit) * 10); } }

.features__intro-text {
  grid-column: 8 / -4;
  grid-row: 2;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  max-width: 24em;
  line-height: 39px; }
  @media (max-width: 800px) {
    .features__intro-text {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }
  @media (max-width: 1200px) {
    .features__intro-text {
      grid-column: 2 / -2; } }
  @media (max-width: 599px) {
    .features__intro-text {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: -0.005em; } }
  @media (max-width: 599px) and (max-width: 599px) {
    .features__intro-text {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }

.features__quicklinks {
  grid-column: 2 / 7;
  grid-row: 2;
  padding-right: calc(var(--spacing-unit) * 5);
  hyphens: none;
  -webkit-hyphens: none; }
  @media (max-width: 1200px) {
    .features__quicklinks {
      display: none; } }

.features__collect {
  margin-bottom: calc(var(--spacing-unit) * 25);
  grid-template-rows: [headline] auto [intro] auto [screenshotBOverhang] calc(var(--spacing-unit) * 19) [screenshotB] auto [screenshotBOverlap] calc(var(--spacing-unit) * 26) [screenshotBCaption] auto [screenshotA] auto [screenshotCOverlap] calc(var(--spacing-unit) * 10) [screenshotACaption] auto [screenshotC] auto [screenshotCCaption] auto [outro] auto; }
  @media (max-width: 1200px) {
    .features__collect {
      grid-template-rows: [headline] auto [intro] auto [screenshotA] auto [screenshotACaption] auto [screenshotBOverhang] calc( var(--spacing-unit) * 19) [screenshotB] auto [screenshotCOverlap] calc(var(--spacing-unit) * 10) [screenshotC] auto [screenshotCCaption] auto [outro] auto; } }
  @media (max-width: 1200px) {
    .features__collect {
      grid-template-rows: [headline] auto [intro] auto [screenshotA] auto [screenshotACaption] auto [screenshotBOverhang] calc( var(--spacing-unit) * 19) [screenshotB] auto [screenshotCOverlap] calc(var(--spacing-unit) * 7) [screenshotC] auto [screenshotCCaption] auto [outro] auto; } }
  @media (max-width: 599px) {
    .features__collect {
      margin-bottom: calc(var(--spacing-unit) * 20);
      grid-template-rows: [headline] auto [intro] auto [screenshotA] auto [screenshotACaption] auto [screenshotBOverhang] calc( var(--spacing-unit) * 23) [screenshotB] auto [screenshotBCaption] auto [screenshotC] auto [screenshotCCaption] auto [outro] auto; } }

.features__collect-headline {
  grid-column: 2 / -2;
  grid-row: headline;
  font-size: 62px;
  line-height: 64px;
  letter-spacing: -0.025em;
  font-weight: var(--font-weight-medium);
  hyphens: none;
  -webkit-hyphens: none;
  max-width: 13em;
  margin-bottom: calc(var(--spacing-unit) * 8); }
  @media (max-width: 800px) {
    .features__collect-headline {
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -0.02em; } }
  .features__collect-headline strong {
    color: var(--green); }
  .features__collect-headline i {
    font-style: normal;
    opacity: 0.3; }
  @media (max-width: 900px) {
    .features__collect-headline {
      font-size: 50px;
      line-height: 54px;
      letter-spacing: -0.02em;
      hyphens: auto;
      -webkit-hyphens: auto; } }
  @media (max-width: 900px) and (max-width: 800px) {
    .features__collect-headline {
      font-size: 32px;
      line-height: 35px;
      letter-spacing: -0.02em; } }
  @media (max-width: 599px) {
    .features__collect-headline {
      margin-bottom: calc(var(--spacing-unit) * 6); } }

.features__collect-description {
  grid-column: 2 / 12;
  grid-row: intro / screenshotB;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  max-width: 27em;
  padding-right: calc(var(--spacing-unit) * 10);
  margin-bottom: calc(var(--spacing-unit) * 14); }
  @media (max-width: 599px) {
    .features__collect-description {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  @media (max-width: 1200px) {
    .features__collect-description {
      grid-column: 2 / -2;
      grid-row: intro;
      padding-right: 0; } }
  @media (max-width: 599px) {
    .features__collect-description {
      margin-bottom: calc(var(--spacing-unit) * 8); } }

.features__collect-screenshot-a {
  grid-column: 2 / -8;
  grid-row: screenshotBOverlap / screenshotACaption;
  padding-left: calc(var(--spacing-unit) * 7);
  padding-right: calc(var(--spacing-unit) * 7); }
  @media (max-width: 1200px) {
    .features__collect-screenshot-a {
      grid-column: 2 / -3;
      grid-row: screenshotA;
      padding-top: calc(var(--spacing-unit) * 7);
      padding-right: 0; } }
  @media (max-width: 599px) {
    .features__collect-screenshot-a {
      grid-column: 2 / -2;
      padding-left: calc(var(--spacing-unit) * 3);
      padding-top: calc(var(--spacing-unit) * 3); } }

.features__collect-screenshot-a-image {
  transform-style: flat;
  width: 100%;
  object-fit: contain;
  background-color: var(--gray-500);
  border-radius: 3px;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.25);
  transform: perspective(1000px) rotateX(2deg) rotateY(2deg) rotateZ(0deg) scale(1);
  transition: all 0.6s ease-in-out; }

.features__collect-caption-a {
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  grid-column: 2 / 12;
  grid-row: screenshotACaption;
  color: var(--white);
  margin-top: calc(var(--spacing-unit) * 3);
  max-width: 24em;
  padding-left: calc(var(--spacing-unit) * 7);
  align-self: start; }
  @media (max-width: 1200px) {
    .features__collect-caption-a {
      grid-column: 2 / 20;
      padding-right: calc(var(--spacing-unit) * 7); } }
  @media (max-width: 900px) {
    .features__collect-caption-a {
      grid-column: 2 / -6; } }
  @media (max-width: 599px) {
    .features__collect-caption-a {
      padding-left: calc(var(--spacing-unit) * 3);
      padding-right: calc(var(--spacing-unit) * 3);
      margin-top: calc(var(--spacing-unit) * 1.5); } }

.features__collect-question-sets {
  grid-column: 18 / -2;
  grid-row: screenshotBOverhang / screenshotBCaption;
  padding-right: calc(var(--spacing-unit) * 7);
  z-index: 2;
  transform: perspective(1000px) rotateX(1deg) rotateY(-5deg) rotateZ(0deg) scale(1) translateZ(30px); }
  @media (max-width: 1200px) {
    .features__collect-question-sets {
      grid-column: 16 / -2;
      grid-row: screenshotBOverhang / screenshotC;
      padding-right: 0; } }
  @media (max-width: 900px) {
    .features__collect-question-sets {
      grid-column: 14 / -2; } }
  @media (max-width: 599px) {
    .features__collect-question-sets {
      grid-column: 6 / -2;
      grid-row: screenshotBOverhang / screenshotBCaption;
      padding-top: calc(var(--spacing-unit) * 8);
      padding-right: calc(var(--spacing-unit) * 2);
      transform: perspective(1000px) rotateX(1deg) rotateY(-2.5deg) rotateZ(0deg) scale(1); } }

.features__collect-question-set {
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 3px;
  margin-bottom: calc(var(--spacing-unit) * 1);
  padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 2);
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.25); }
  .features__collect-question-set:last-child {
    margin-bottom: 0; }
  @media (max-width: 599px) {
    .features__collect-question-set {
      font-size: 13.5px;
      line-height: 19px;
      letter-spacing: 0.02em;
      margin-bottom: calc(var(--spacing-unit) * 1);
      padding: calc(var(--spacing-unit) * 1.2) calc(var(--spacing-unit) * 2); } }

.features__collect-question-set-meta {
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: var(--gray-700); }
  @media (max-width: 599px) {
    .features__collect-question-set-meta {
      font-size: 12px;
      line-height: 16px; } }

.features__collect-caption-b {
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  opacity: 0.8;
  grid-column: -8 / -2;
  grid-row: screenshotBCaption;
  margin-top: calc(var(--spacing-unit) * 4);
  max-width: 24em;
  padding-right: calc(var(--spacing-unit) * 7);
  padding-bottom: calc(var(--spacing-unit) * 7); }
  @media (max-width: 1200px) {
    .features__collect-caption-b {
      grid-row: screenshotB;
      grid-column: 9 / 16;
      padding-right: calc(var(--spacing-unit) * 5);
      padding-left: calc(var(--spacing-unit) * 5);
      padding-top: calc(var(--spacing-unit) * 5);
      margin: 0; } }
  @media (max-width: 900px) {
    .features__collect-caption-b {
      grid-column: 5 / 14; } }
  @media (max-width: 599px) {
    .features__collect-caption-b {
      grid-row: screenshotBCaption;
      grid-column: 5 / -2;
      padding-right: calc(var(--spacing-unit) * 3);
      padding-left: calc(var(--spacing-unit) * 3);
      padding-top: calc(var(--spacing-unit) * 0);
      margin-top: calc(var(--spacing-unit) * 2); } }

.features__collect-screenshot-c {
  grid-column: 12 / -4;
  grid-row: screenshotCOverlap / screenshotCCaption;
  padding-left: calc(var(--spacing-unit) * 7); }
  @media (max-width: 1200px) {
    .features__collect-screenshot-c {
      grid-column: 2 / -4;
      grid-row: screenshotCOverlap / screenshotCCaption; } }
  @media (max-width: 900px) {
    .features__collect-screenshot-c {
      padding-left: calc(var(--spacing-unit) * 3); } }
  @media (max-width: 599px) {
    .features__collect-screenshot-c {
      grid-column: 2 / -2;
      padding-right: calc(var(--spacing-unit) * 3);
      grid-row: screenshotC / screenshotCCaption;
      margin-top: calc(var(--spacing-unit) * 4); } }

.features__collect-screenshot-c-image {
  transform-style: flat;
  width: 100%;
  object-fit: contain;
  background-color: var(--gray-500);
  border-radius: 3px;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.25);
  transform: perspective(1000px) rotateX(3deg) rotateY(-1deg) rotateZ(0deg) scale(1) translateZ(22px);
  transition: all 0.6s ease-in-out; }

.features__collect-caption-c {
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  opacity: 0.8;
  grid-column: 12 / -3;
  grid-row: screenshotCCaption;
  margin-top: calc(var(--spacing-unit) * 3);
  max-width: 24em;
  padding-left: calc(var(--spacing-unit) * 7);
  padding-bottom: calc(var(--spacing-unit) * 7); }
  @media (max-width: 1200px) {
    .features__collect-caption-c {
      grid-column: 9 / -5;
      padding-left: calc(var(--spacing-unit) * 5);
      padding-bottom: calc(var(--spacing-unit) * 5);
      padding-right: calc(var(--spacing-unit) * 5); } }
  @media (max-width: 900px) {
    .features__collect-caption-c {
      grid-column: 5 / -3; } }
  @media (max-width: 599px) {
    .features__collect-caption-c {
      grid-column: 5 / -2;
      padding-left: calc(var(--spacing-unit) * 3);
      padding-bottom: calc(var(--spacing-unit) * 3);
      padding-right: calc(var(--spacing-unit) * 3);
      margin-top: calc(var(--spacing-unit) * 1.5); } }

.features__collect-outro {
  grid-column: 2 / 12;
  grid-row: screenshotCCaption / -1;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  max-width: 27em;
  padding-right: calc(var(--spacing-unit) * 7);
  padding-left: calc(var(--spacing-unit) * 7);
  padding-bottom: calc(var(--spacing-unit) * 7);
  color: var(--white); }
  @media (max-width: 800px) {
    .features__collect-outro {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }
  @media (max-width: 1200px) {
    .features__collect-outro {
      grid-row: outro;
      grid-column: 2 / 20;
      padding-top: calc(var(--spacing-unit) * 10); } }
  @media (max-width: 900px) {
    .features__collect-outro {
      grid-column: 2 / -6; } }
  @media (max-width: 599px) {
    .features__collect-outro {
      grid-column: 2 / -4;
      padding-right: calc(var(--spacing-unit) * 4);
      padding-left: calc(var(--spacing-unit) * 4);
      padding-bottom: calc(var(--spacing-unit) * 4); } }

.features__collect-background-green {
  background-color: var(--green);
  grid-column: 12 / -2;
  grid-row: screenshotB / outro; }
  @media (max-width: 1200px) {
    .features__collect-background-green {
      grid-column: 9 / -5;
      grid-row: screenshotB / outro; } }
  @media (max-width: 900px) {
    .features__collect-background-green {
      grid-column: 5 / -3; } }
  @media (max-width: 599px) {
    .features__collect-background-green {
      grid-column: 5 / -2; } }

.features__collect-background-dark {
  background-color: var(--gray-900);
  grid-column: 2 / 15;
  grid-row: screenshotBCaption / -1;
  z-index: -1;
  position: relative;
  overflow: hidden; }
  .features__collect-background-dark .key-visual {
    opacity: 0.6; }
  @media (max-width: 1200px) {
    .features__collect-background-dark {
      grid-row: screenshotA / -1;
      grid-column: 2 / 20; } }
  @media (max-width: 900px) {
    .features__collect-background-dark {
      grid-column: 2 / -6; } }
  @media (max-width: 599px) {
    .features__collect-background-dark {
      grid-column: 2 / -4; } }

.features__explore {
  margin-bottom: calc(var(--spacing-unit) * 25);
  grid-template-rows: [headline] auto [intro] auto [screenshotAOverhang] calc(var(--spacing-unit) * 25) [screenshotA] auto [screenshotACaption] auto [screenshotBOverlap] calc(var(--spacing-unit) * 14) [screenshotB] auto [screenshotBCaption] auto [screenshotC] auto [screenshotCCaption] auto [screenshotD] auto [screenshotDCaption] auto [screenshotDOverhang] calc( var(--spacing-unit) * 13); }
  @media (max-width: 599px) {
    .features__explore {
      margin-bottom: calc(var(--spacing-unit) * 20);
      grid-template-rows: [headline] auto [intro] auto [screenshotAOverhang] calc(var(--spacing-unit) * 10) [screenshotA] auto [screenshotACaption] auto [screenshotBOverlap] calc(var(--spacing-unit) * 10) [screenshotB] auto [screenshotBCaption] auto [screenshotC] auto [screenshotCCaption] auto [screenshotD] auto [screenshotDCaption] auto; } }

.features__explore-headline {
  grid-column: 2 / -2;
  grid-row: headline;
  font-size: 62px;
  line-height: 64px;
  letter-spacing: -0.025em;
  font-weight: var(--font-weight-medium);
  hyphens: none;
  -webkit-hyphens: none;
  max-width: 14em;
  margin-bottom: calc(var(--spacing-unit) * 8); }
  @media (max-width: 800px) {
    .features__explore-headline {
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -0.02em; } }
  .features__explore-headline strong {
    color: var(--highlight); }
  .features__explore-headline i {
    font-style: normal;
    opacity: 0.3; }
  @media (max-width: 900px) {
    .features__explore-headline {
      font-size: 50px;
      line-height: 54px;
      letter-spacing: -0.02em;
      hyphens: auto;
      -webkit-hyphens: auto; } }
  @media (max-width: 900px) and (max-width: 800px) {
    .features__explore-headline {
      font-size: 32px;
      line-height: 35px;
      letter-spacing: -0.02em; } }
  @media (max-width: 599px) {
    .features__explore-headline {
      margin-bottom: calc(var(--spacing-unit) * 6); } }

.features__explore-description {
  grid-column: 2 / -2;
  grid-row: intro;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  max-width: 24em;
  hyphens: manual;
  -webkit-hyphens: manual;
  margin-bottom: calc(var(--spacing-unit) * 10); }
  @media (max-width: 599px) {
    .features__explore-description {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  @media (max-width: 599px) {
    .features__explore-description {
      margin-bottom: calc(var(--spacing-unit) * 8); } }

.features__explore-screenshot-a {
  grid-column: 2 / -4;
  grid-row: screenshotAOverhang / screenshotACaption;
  padding-left: calc(var(--spacing-unit) * 7); }
  @media (max-width: 900px) {
    .features__explore-screenshot-a {
      grid-column: 2 / -2;
      padding-left: calc(var(--spacing-unit) * 5); } }
  @media (max-width: 599px) {
    .features__explore-screenshot-a {
      padding-left: calc(var(--spacing-unit) * 3); } }

.features__explore-screenshot-a-image {
  transform-style: flat;
  width: 100%;
  object-fit: contain;
  background-color: var(--gray-500);
  border-radius: 3px;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.25);
  transform: perspective(1000px) rotateX(3deg) rotateY(0deg) rotateZ(0deg) scale(1);
  transition: all 0.6s ease-in-out; }

.features__explore-caption-a {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  grid-column: 2 / 16;
  grid-row: screenshotACaption / screenshotB;
  margin-top: calc(var(--spacing-unit) * 7);
  max-width: 24em;
  padding-left: calc(var(--spacing-unit) * 7);
  padding-right: calc(var(--spacing-unit) * 7);
  padding-bottom: calc(var(--spacing-unit) * 7); }
  @media (max-width: 599px) {
    .features__explore-caption-a {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  .features__explore-caption-a strong {
    color: var(--highlight); }
  @media (max-width: 900px) {
    .features__explore-caption-a {
      grid-column: 2 / 15;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.009em;
      text-underline-offset: 1.5px;
      margin-top: calc(var(--spacing-unit) * 5);
      padding-left: calc(var(--spacing-unit) * 5);
      padding-right: calc(var(--spacing-unit) * 5);
      padding-bottom: calc(var(--spacing-unit) * 5); } }
  @media (max-width: 599px) {
    .features__explore-caption-a {
      grid-row: screenshotACaption;
      grid-column: 2 / -4;
      padding-left: calc(var(--spacing-unit) * 3);
      padding-right: calc(var(--spacing-unit) * 3);
      padding-bottom: calc(var(--spacing-unit) * 8);
      margin-top: calc(var(--spacing-unit) * 2); } }

.features__explore-screenshot-b {
  grid-column: 16 / -2;
  grid-row: screenshotBOverlap / screenshotBCaption; }
  @media (max-width: 900px) {
    .features__explore-screenshot-b {
      grid-column: 14 / -2;
      padding-left: calc(var(--spacing-unit) * 5); } }
  @media (max-width: 599px) {
    .features__explore-screenshot-b {
      grid-column: 5 / -2;
      padding-left: 0;
      padding-right: calc(var(--spacing-unit) * 3); } }

.features__explore-screenshot-b-image {
  transform-style: flat;
  width: 100%;
  object-fit: contain;
  background-color: var(--gray-500);
  border-radius: 3px;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.25);
  transform: perspective(1000px) rotateX(1deg) rotateY(-5deg) rotateZ(0deg) scale(1);
  transition: all 0.6s ease-in-out; }

.features__explore-caption-b {
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: var(--white);
  grid-column: 16 / -2;
  grid-row: screenshotBCaption;
  margin-top: calc(var(--spacing-unit) * 3);
  max-width: 27em;
  padding-right: calc(var(--spacing-unit) * 7); }
  @media (max-width: 900px) {
    .features__explore-caption-b {
      grid-column: 14 / -2;
      padding-right: calc(var(--spacing-unit) * 5);
      padding-left: calc(var(--spacing-unit) * 5); } }
  @media (max-width: 599px) {
    .features__explore-caption-b {
      grid-column: 5 / -2;
      padding-right: calc(var(--spacing-unit) * 3);
      padding-left: 0;
      margin-top: calc(var(--spacing-unit) * 1.5);
      margin-bottom: calc(var(--spacing-unit) * 10); } }

.features__explore-screenshot-c {
  grid-column: 2 / 11;
  grid-row: screenshotBCaption / screenshotD; }
  @media (max-width: 1200px) {
    .features__explore-screenshot-c {
      grid-row: screenshotBCaption / screenshotCCaption; } }
  @media (max-width: 900px) {
    .features__explore-screenshot-c {
      grid-column: 2 / 14; } }
  @media (max-width: 599px) {
    .features__explore-screenshot-c {
      grid-row: screenshotC;
      grid-column: 2 / -7; } }

.features__explore-screenshot-c-image {
  transform-style: flat;
  width: 100%;
  object-fit: contain;
  background-color: var(--gray-500);
  border-radius: 3px;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.25);
  transform: perspective(1000px) rotateX(4deg) rotateY(3deg) rotateZ(0deg) scale(1);
  transition: all 0.6s ease-in-out; }

.features__explore-caption-c {
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: var(--white);
  grid-column: 11 / -2;
  grid-row: screenshotCCaption;
  margin-top: calc(var(--spacing-unit) * 3);
  max-width: 22em;
  padding-left: calc(var(--spacing-unit) * 3); }
  @media (max-width: 1200px) {
    .features__explore-caption-c {
      grid-column: 6 / -2;
      padding-left: calc(var(--spacing-unit) * 5);
      padding-right: calc(var(--spacing-unit) * 5);
      font-size: 13.5px;
      line-height: 19px;
      letter-spacing: 0.02em;
      max-width: 24em; } }
  @media (max-width: 599px) {
    .features__explore-caption-c {
      grid-column: 4 / -2;
      padding-left: calc(var(--spacing-unit) * 3);
      margin-top: calc(var(--spacing-unit) * 1.5); } }

.features__explore-screenshot-d {
  grid-column: 12 / -2;
  grid-row: screenshotD / -1;
  margin-top: calc(var(--spacing-unit) * 15);
  padding-left: calc(var(--spacing-unit) * 7); }
  @media (max-width: 1200px) {
    .features__explore-screenshot-d {
      padding-left: calc(var(--spacing-unit) * 5); } }
  @media (max-width: 599px) {
    .features__explore-screenshot-d {
      grid-row: screenshotD;
      grid-column: 4 / -2;
      padding-left: calc(var(--spacing-unit) * 3);
      padding-right: calc(var(--spacing-unit) * 3);
      margin-top: calc(var(--spacing-unit) * 10); } }

.features__explore-screenshot-d-image {
  transform-style: flat;
  width: 100%;
  object-fit: contain;
  background-color: var(--gray-500);
  border-radius: 3px;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.25);
  transform: perspective(1000px) rotateX(3deg) rotateY(-2deg) rotateZ(0deg) scale(1);
  transition: all 0.6s ease-in-out; }

.features__explore-caption-d {
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: var(--white);
  grid-column: 6 / 12;
  grid-row: screenshotDCaption;
  margin-top: calc(var(--spacing-unit) * 3);
  max-width: 24em;
  padding-left: calc(var(--spacing-unit) * 7);
  padding-bottom: calc(var(--spacing-unit) * 7); }
  @media (max-width: 1200px) {
    .features__explore-caption-d {
      padding-left: calc(var(--spacing-unit) * 5);
      padding-bottom: calc(var(--spacing-unit) * 5); } }
  @media (max-width: 599px) {
    .features__explore-caption-d {
      grid-column: 4 / -2;
      padding-left: calc(var(--spacing-unit) * 3);
      padding-right: calc(var(--spacing-unit) * 3);
      margin-top: calc(var(--spacing-unit) * 1.5); } }

.features__explore-background-light {
  background-color: var(--gray-150);
  grid-column: 2 / 18;
  grid-row: screenshotA / screenshotB; }
  @media (max-width: 599px) {
    .features__explore-background-light {
      grid-column: 2 / -4; } }

.features__explore-background-dark {
  background-color: var(--gray-800);
  grid-column: 6 / -3;
  grid-row: screenshotB / -2;
  z-index: -1;
  position: relative;
  overflow: hidden; }
  .features__explore-background-dark .key-visual {
    opacity: 0.2; }
  @media (max-width: 599px) {
    .features__explore-background-dark {
      grid-column: 4 / -2;
      grid-row: screenshotB / -1; } }

.features__present {
  margin-bottom: calc(var(--spacing-unit) * 25);
  grid-template-rows: [headline] auto [intro] auto [screenshotAOverhang] calc(var(--spacing-unit) * 15) [screenshotA] 1fr [outro] auto [backgroundLightOverhang] calc(var(--spacing-unit) * 8); }
  @media (max-width: 900px) {
    .features__present {
      grid-template-rows: [headline] auto [intro] auto [screenshotAOverhang] calc(var(--spacing-unit) * 15) [screenshotA] 1fr [screenshotACaption] auto [outro] auto [backgroundLightOverhang] calc(var(--spacing-unit) * 6); } }
  @media (max-width: 599px) {
    .features__present {
      margin-bottom: calc(var(--spacing-unit) * 20);
      grid-template-rows: [headline] auto [intro] auto [screenshotAOverhang] calc(var(--spacing-unit) * 10) [screenshotA] 1fr [screenshotACaption] auto [outro] auto [backgroundLightOverhang] calc(var(--spacing-unit) * 3); } }

.features__present-headline {
  grid-column: 2 / -2;
  grid-row: headline;
  font-size: 62px;
  line-height: 64px;
  letter-spacing: -0.025em;
  font-weight: var(--font-weight-medium);
  hyphens: none;
  -webkit-hyphens: none;
  max-width: 18em;
  margin-bottom: calc(var(--spacing-unit) * 8); }
  @media (max-width: 800px) {
    .features__present-headline {
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -0.02em; } }
  .features__present-headline strong {
    color: var(--red); }
  .features__present-headline i {
    font-style: normal;
    opacity: 0.3; }
  @media (max-width: 900px) {
    .features__present-headline {
      font-size: 50px;
      line-height: 54px;
      letter-spacing: -0.02em;
      hyphens: auto;
      -webkit-hyphens: auto; } }
  @media (max-width: 900px) and (max-width: 800px) {
    .features__present-headline {
      font-size: 32px;
      line-height: 35px;
      letter-spacing: -0.02em; } }
  @media (max-width: 599px) {
    .features__present-headline {
      margin-bottom: calc(var(--spacing-unit) * 6); } }

.features__present-description {
  grid-column: 2 / 10;
  grid-row: intro / screenshotA;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  max-width: 27em;
  padding-right: calc(var(--spacing-unit) * 7);
  margin-bottom: calc(var(--spacing-unit) * 12); }
  @media (max-width: 599px) {
    .features__present-description {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  @media (max-width: 1200px) {
    .features__present-description {
      grid-column: 2 / -2;
      grid-row: intro;
      padding-right: 0; } }
  @media (max-width: 599px) {
    .features__present-description {
      margin-bottom: calc(var(--spacing-unit) * 8); } }

.features__present-screenshot-a {
  grid-column: 10 / -2;
  grid-row: screenshotAOverhang / backgroundLightOverhang;
  padding-bottom: calc(var(--spacing-unit) * 12); }
  @media (max-width: 1200px) {
    .features__present-screenshot-a {
      grid-row: screenshotAOverhang / outro;
      grid-column: 2 / -2;
      padding-left: calc(var(--spacing-unit) * 5);
      padding-bottom: calc(var(--spacing-unit) * 5); } }
  @media (max-width: 900px) {
    .features__present-screenshot-a {
      grid-row: screenshotAOverhang / screenshotACaption; } }
  @media (max-width: 599px) {
    .features__present-screenshot-a {
      padding-left: calc(var(--spacing-unit) * 3); } }

.features__present-screenshot-a-image {
  transform-style: flat;
  width: 100%;
  object-fit: contain;
  background-color: var(--gray-500);
  border-radius: 3px;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.25);
  transform: perspective(1000px) rotateX(2deg) rotateY(-3deg) rotateZ(0deg) scale(1);
  transition: all 0.6s ease-in-out; }

.features__present-caption-a {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.009em;
  text-underline-offset: 1.5px;
  grid-column: 2 / 10;
  grid-row: screenshotA;
  max-width: 26em;
  padding-left: calc(var(--spacing-unit) * 7);
  padding-right: calc(var(--spacing-unit) * 7);
  padding-top: calc(var(--spacing-unit) * 7);
  padding-bottom: calc(var(--spacing-unit) * 7); }
  @media (max-width: 1200px) {
    .features__present-caption-a {
      grid-row: outro;
      grid-column: 2 / 14;
      padding-left: calc(var(--spacing-unit) * 5);
      padding-right: calc(var(--spacing-unit) * 5);
      padding-top: 0;
      padding-bottom: calc(var(--spacing-unit) * 5); } }
  @media (max-width: 900px) {
    .features__present-caption-a {
      grid-row: screenshotACaption;
      grid-column: 2 / -3; } }
  @media (max-width: 599px) {
    .features__present-caption-a {
      padding-left: calc(var(--spacing-unit) * 3);
      padding-right: calc(var(--spacing-unit) * 3);
      padding-bottom: calc(var(--spacing-unit) * 3); } }

.features__present-outro {
  grid-column: 2 / 10;
  grid-row: outro;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  max-width: 27em;
  padding-right: calc(var(--spacing-unit) * 7);
  padding-left: calc(var(--spacing-unit) * 7);
  padding-bottom: calc(var(--spacing-unit) * 7);
  color: var(--red); }
  @media (max-width: 800px) {
    .features__present-outro {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }
  @media (max-width: 1200px) {
    .features__present-outro {
      grid-column: 14 / -3;
      padding-left: calc(var(--spacing-unit) * 5);
      padding-right: calc(var(--spacing-unit) * 5);
      padding-top: calc(var(--spacing-unit) * 12);
      padding-bottom: calc(var(--spacing-unit) * 5); } }
  @media (max-width: 900px) {
    .features__present-outro {
      grid-column: 2 / -3;
      padding-top: calc(var(--spacing-unit) * 5); } }
  @media (max-width: 599px) {
    .features__present-outro {
      padding-left: calc(var(--spacing-unit) * 3);
      padding-right: calc(var(--spacing-unit) * 3);
      padding-bottom: calc(var(--spacing-unit) * 3); } }

.features__present-background-dark {
  background-color: var(--gray-150);
  grid-column: 2 / -7;
  grid-row: screenshotA / backgroundLightOverhang; }
  @media (max-width: 1200px) {
    .features__present-background-dark {
      grid-column: 2 / -3; } }

.features__present-background-light {
  background-color: var(--gray-300);
  grid-column: 3 / -6;
  grid-row: outro / -1;
  z-index: -1;
  position: relative;
  overflow: hidden; }
  .features__present-background-light .key-visual {
    opacity: 0.1; }
  @media (max-width: 1200px) {
    .features__present-background-light {
      grid-column: 3 / -2; } }
  @media (max-width: 900px) {
    .features__present-background-light {
      grid-row: screenshotACaption / -1;
      grid-column: 3 / -2; } }

.features__slogan {
  margin-top: calc(var(--spacing-unit) * 25); }
  @media (max-width: 599px) {
    .features__slogan {
      margin-top: calc(var(--spacing-unit) * 10); } }

.features__slogan-text {
  grid-column: 2 / -6;
  hyphens: none;
  -webkit-hyphens: none;
  font-size: 62px;
  line-height: 64px;
  letter-spacing: -0.025em;
  font-weight: var(--font-weight-medium); }
  @media (max-width: 800px) {
    .features__slogan-text {
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -0.02em; } }
  .features__slogan-text em {
    color: var(--red); }
  .features__slogan-text a {
    text-underline-offset: 0.06em;
    text-decoration-color: var(--gray-300);
    text-decoration-thickness: 0.06em;
    transition: all 0.8s; }
    .features__slogan-text a:hover {
      text-decoration-color: var(--red); }
  @media (max-width: 1200px) {
    .features__slogan-text {
      hyphens: auto;
      -webkit-hyphens: auto;
      grid-column: 2 / -2; } }
  @media (max-width: 599px) {
    .features__slogan-text {
      font-size: 50px;
      line-height: 54px;
      letter-spacing: -0.02em; } }
  @media (max-width: 599px) and (max-width: 800px) {
    .features__slogan-text {
      font-size: 32px;
      line-height: 35px;
      letter-spacing: -0.02em; } }

body {
  overflow-x: hidden; }

.langing-page__header {
  position: fixed;
  top: calc(var(--spacing-unit) * 4);
  left: 0;
  right: 0;
  z-index: 10; }

.langing-page__header-logo {
  grid-row: 1;
  grid-column: 10 / -2;
  text-align: right; }
  @media (max-width: 599px) {
    .langing-page__header-logo {
      grid-column: 2 / -2; } }

.langing-page__header-nav {
  grid-row: 1;
  grid-column: 2 / 10; }
  @media (max-width: 599px) {
    .langing-page__header-nav {
      display: none; } }

.langing-page__header-nav-early-access {
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  white-space: normal;
  text-align: left;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  font-weight: var(--font-weight-medium);
  background-color: var(--red);
  color: var(--white);
  border-radius: 3px;
  padding: calc(var(--spacing-unit) * 0.5) calc(var(--spacing-unit) * 1.66);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s; }

.langing-page__header--show-button .langing-page__header-nav-early-access {
  opacity: 1; }

.landing-page__intro {
  margin-bottom: calc(var(--spacing-unit) * 25);
  overflow: hidden;
  grid-template-rows: 1fr calc(var(--spacing-unit) * 10) auto; }
  @media (max-width: 1200px) {
    .landing-page__intro {
      grid-template-rows: auto calc(var(--spacing-unit) * 6) auto calc(var(--spacing-unit) * 10) auto;
      margin-bottom: calc(var(--spacing-unit) * 17); } }
  @media (max-width: 599px) {
    .landing-page__intro {
      margin-bottom: calc(var(--spacing-unit) * 12); } }

.landing-page__key-visual {
  position: relative;
  background-color: var(--gray-150);
  grid-column: 1 / -1;
  grid-row: 1/3;
  z-index: -2; }
  .landing-page__key-visual img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    opacity: 0.05; }
  @media (max-width: 1200px) {
    .landing-page__key-visual {
      grid-row: 3/5; } }

.landing-page__intro-content {
  grid-column: 2 / -13;
  grid-row: 1;
  padding-top: calc(var(--spacing-unit) * 14);
  padding-right: calc(var(--spacing-unit) * 10);
  padding-bottom: calc(var(--spacing-unit) * 10); }
  @media (max-width: 1200px) {
    .landing-page__intro-content {
      grid-column: 2 / -2;
      grid-row: 1; } }
  @media (max-width: 599px) {
    .landing-page__intro-content {
      padding-top: calc(var(--spacing-unit) * 10);
      padding-bottom: calc(var(--spacing-unit) * 8); } }

.landing-page__kicker {
  font-weight: var(--font-weight-medium);
  color: var(--gray-700);
  margin-bottom: calc(var(--spacing-unit) * 1); }

.landing-page__headline {
  font-size: 62px;
  line-height: 64px;
  letter-spacing: -0.025em;
  font-weight: var(--font-weight-medium);
  color: var(--red);
  margin-bottom: calc(var(--spacing-unit) * 6);
  max-width: 14em; }
  @media (max-width: 800px) {
    .landing-page__headline {
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -0.02em; } }

.landing-page__headline em {
  font-weight: var(--font-weight-bold);
  color: var(--red); }

.landing-page__intro-text p {
  max-width: 27em; }

.landing-page__intro-screenshot {
  grid-column: -13 / -2;
  grid-row: 1 / 4;
  padding-top: calc(var(--spacing-unit) * 16);
  padding-bottom: calc(var(--spacing-unit) * 8); }
  @media (max-width: 1200px) {
    .landing-page__intro-screenshot {
      padding-top: 0;
      grid-column: 2 / -2;
      grid-row: 2/4; } }
  @media (max-width: 599px) {
    .landing-page__intro-screenshot {
      padding-bottom: calc(var(--spacing-unit) * 6); } }

.landing-page__intro-screenshot-image {
  width: 160%;
  transform-style: flat;
  background-color: var(--gray-500);
  border-radius: 3px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.25);
  transform: perspective(1000px) rotateX(3deg) rotateY(-2deg) rotateZ(0deg); }
  @media (max-width: 1200px) {
    .landing-page__intro-screenshot-image {
      width: 100%; } }

.landing-page__intro-cta {
  grid-column: 2 / -16;
  grid-row: 2/4;
  padding-left: calc(var(--spacing-unit) * 4.5);
  padding-right: calc(var(--spacing-unit) * 4.5);
  padding-top: calc(var(--spacing-unit) * 3.5);
  padding-bottom: calc(var(--spacing-unit) * 5); }
  @media (max-width: 1200px) {
    .landing-page__intro-cta {
      grid-column: 6 / -6;
      grid-row: 4/6;
      padding-right: calc(var(--spacing-unit) * 4.5); } }
  @media (max-width: 900px) {
    .landing-page__intro-cta {
      grid-column: 4 / -4; } }
  @media (max-width: 599px) {
    .landing-page__intro-cta {
      grid-column: 2 / -2;
      padding-left: 0;
      padding-right: 0;
      padding-top: calc(var(--spacing-unit) * 2.5);
      padding-bottom: calc(var(--spacing-unit) * 3.5);
      text-align: center;
      margin-left: auto;
      margin-right: auto; } }

.landing-page__intro-cta-text {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  color: var(--white);
  margin-bottom: calc(var(--spacing-unit) * 3); }
  @media (max-width: 599px) {
    .landing-page__intro-cta-text {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  @media (max-width: 599px) {
    .landing-page__intro-cta-text {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.009em;
      text-underline-offset: 1.5px;
      text-align: center; } }

.landing-page__intro-cta-text p {
  max-width: 18em;
  hyphens: none;
  -webkit-hyphens: none; }

@media (max-width: 599px) {
  .landing-page__intro-cta-form {
    display: flex;
    justify-content: center; } }

.landing-page__signup-form {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr auto;
  column-gap: calc(var(--spacing-unit) * 2); }
  @media (max-width: 599px) {
    .landing-page__signup-form {
      grid-template-columns: 1fr; } }

.landing-page__signup-form.signup-form--error .landing-page__signup-form-error {
  display: block; }

@media (max-width: 599px) {
  .landing-page__signup-form.signup-form--error .landing-page__signup-form-button {
    grid-row: 4; } }

.landing-page__signup-form.signup-form--success .landing-page__signup-form-success {
  display: block; }

.landing-page__signup-form.signup-form--success .landing-page__signup-form-button,
.landing-page__signup-form.signup-form--success .landing-page__signup-form-input,
.landing-page__signup-form.signup-form--success .landing-page__signup-form-beta {
  display: none; }

.landing-page__signup-form-input {
  grid-row: 2;
  grid-column: 1;
  z-index: 1;
  position: relative; }

.landing-page__signup-form-button {
  grid-row: 2;
  grid-column: 2; }
  @media (max-width: 599px) {
    .landing-page__signup-form-button {
      margin-top: calc(var(--spacing-unit) * 2);
      grid-row: 3;
      grid-column: 1;
      justify-self: start; } }

.landing-page__signup-form-error {
  display: none;
  grid-row: 3;
  grid-column: 1 / -1;
  margin-top: calc(var(--spacing-unit) * 1);
  color: var(--white); }

.landing-page__signup-form-success {
  display: none;
  grid-row: 2 / -1;
  grid-column: 1 / -1;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  max-width: 19em;
  color: var(--green); }
  @media (max-width: 599px) {
    .landing-page__signup-form-success {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }

.landing-page__intro-cta-background {
  background-color: var(--gray-900);
  grid-column: 2 / -16;
  grid-row: 2/4;
  z-index: -2; }
  @media (max-width: 1200px) {
    .landing-page__intro-cta-background {
      grid-column: 6 / -6;
      grid-row: 4/6; } }
  @media (max-width: 900px) {
    .landing-page__intro-cta-background {
      grid-column: 4 / -4; } }
  @media (max-width: 599px) {
    .landing-page__intro-cta-background {
      grid-column: 1 / -1; } }

.landing-page__section-a {
  grid-template-rows: calc(var(--spacing-unit) * 10) 1fr calc(var(--spacing-unit) * 10);
  margin-bottom: calc(var(--spacing-unit) * 22); }
  @media (max-width: 900px) {
    .landing-page__section-a {
      grid-template-rows: auto auto; } }
  @media (max-width: 599px) {
    .landing-page__section-a {
      margin-bottom: calc(var(--spacing-unit) * 12); } }

.landing-page__section-a-visual-background {
  background-color: var(--gray-150);
  grid-column: 1 / 9;
  grid-row: 1 / 3; }
  @media (max-width: 900px) {
    .landing-page__section-a-visual-background {
      display: none; } }

.landing-page__section-a-visual {
  grid-column: 2 / 14;
  grid-row: 2 / 4;
  position: relative; }
  .landing-page__section-a-visual img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  @media (max-width: 900px) {
    .landing-page__section-a-visual {
      grid-row: 2;
      grid-column: 2 / -2;
      height: calc(var(--spacing-unit) * 50); } }
  @media (max-width: 599px) {
    .landing-page__section-a-visual {
      height: calc(var(--spacing-unit) * 40); } }

.landing-page__section-a-content {
  grid-column: 14 / -2;
  grid-row: 2 / 4;
  max-width: calc(var(--spacing-unit) * 80);
  padding-bottom: calc(var(--spacing-unit) * 15);
  padding-left: calc(var(--spacing-unit) * 10); }
  @media (max-width: 900px) {
    .landing-page__section-a-content {
      grid-row: 1;
      grid-column: 2 / -2;
      padding-left: 0;
      padding-bottom: calc(var(--spacing-unit) * 8); } }

.landing-page__section-a-headline {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  font-weight: var(--font-weight-medium);
  margin-bottom: calc(var(--spacing-unit) * 5);
  hyphens: none;
  -webkit-hyphens: none; }
  @media (max-width: 800px) {
    .landing-page__section-a-headline {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }

.landing-page__section-a-text {
  color: var(--gray-800); }

.landing-page__section-b-and-c {
  grid-template-rows: auto calc(var(--spacing-unit) * 30) auto auto;
  margin-bottom: calc(var(--spacing-unit) * 22); }
  @media (max-width: 599px) {
    .landing-page__section-b-and-c {
      margin-bottom: calc(var(--spacing-unit) * 12); } }

.landing-page__section-c-background {
  grid-column: 1 / -1;
  grid-row: 2 / -1;
  background-color: var(--gray-900); }

.landing-page__section-b-content {
  grid-row: 1;
  grid-column: 4 / -11;
  padding-right: calc(var(--spacing-unit) * 10);
  padding-bottom: calc(var(--spacing-unit) * 20); }
  @media (max-width: 1200px) {
    .landing-page__section-b-content {
      grid-column: 4 / -2;
      padding-right: 0; } }
  @media (max-width: 599px) {
    .landing-page__section-b-content {
      padding-bottom: calc(var(--spacing-unit) * 12); } }
  .landing-page__section-b-content p {
    color: var(--gray-800);
    max-width: 25em; }
  .landing-page__section-b-content ul {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.01em;
    font-weight: var(--font-weight-medium);
    margin: calc(var(--spacing-unit) * 4) 0;
    padding: 0;
    list-style: none; }
    @media (max-width: 800px) {
      .landing-page__section-b-content ul {
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.02em; } }
  .landing-page__section-b-content li {
    max-width: 18em;
    position: relative; }
    .landing-page__section-b-content li::before {
      position: absolute;
      left: -38px;
      top: 11px;
      display: block;
      content: "";
      width: 30px;
      height: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='%2300c7b1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5054 4.50879L13 5L12.5054 5.50488L8.22217 9.77979L7.26758 8.875L8.41309 7.68799L10.7788 5.56299L9.16017 5.6792H0.660161V5.00684L0.660156 4.33447H9.16017L10.7705 4.45068L8.41309 2.32568L7.26758 1.13867L8.22217 0.233887L12.5054 4.50879Z' /%3E%3C/svg%3E%0A"); }
  .landing-page__section-b-content li + li {
    margin-top: calc(var(--spacing-unit) * 2); }

.landing-page__section-b-visual {
  grid-column: -11 / -2;
  grid-row: 1 / 3;
  position: relative; }
  .landing-page__section-b-visual img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  @media (max-width: 1200px) {
    .landing-page__section-b-visual {
      display: none; } }

.landing-page__section-c-content {
  grid-row: 2 / 4;
  grid-column: 2 / -12;
  padding-right: calc(var(--spacing-unit) * 10);
  padding-top: calc(var(--spacing-unit) * 10);
  padding-bottom: calc(var(--spacing-unit) * 15); }
  @media (max-width: 1200px) {
    .landing-page__section-c-content {
      grid-column: 2 / -2;
      padding-right: 0; } }
  @media (max-width: 599px) {
    .landing-page__section-c-content {
      padding-top: calc(var(--spacing-unit) * 8);
      padding-bottom: calc(var(--spacing-unit) * 6); } }

.landing-page__section-c-headline {
  font-size: 62px;
  line-height: 64px;
  letter-spacing: -0.025em;
  font-weight: var(--font-weight-medium);
  color: var(--green);
  margin-bottom: calc(var(--spacing-unit) * 6); }
  @media (max-width: 800px) {
    .landing-page__section-c-headline {
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -0.02em; } }

.landing-page__section-c-text p {
  color: var(--gray-200);
  max-width: 27em; }

.landing-page__section-c-graph {
  grid-row: 4;
  grid-column: 3 / -3;
  color: var(--white);
  padding-bottom: calc(var(--spacing-unit) * 15); }
  @media (max-width: 1200px) {
    .landing-page__section-c-graph {
      grid-column: 2 / -2; } }
  @media (max-width: 599px) {
    .landing-page__section-c-graph {
      padding-bottom: calc(var(--spacing-unit) * 8); } }
  .landing-page__section-c-graph ul {
    padding: 0;
    display: grid;
    grid-auto-flow: column; }
    @media (max-width: 599px) {
      .landing-page__section-c-graph ul {
        display: block; } }
  .landing-page__section-c-graph li {
    list-style: none;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.01em;
    font-weight: var(--font-weight-medium);
    text-align: center;
    animation-name: pulse;
    --duration: 4s;
    animation-duration: var(--duration);
    animation-iteration-count: infinite;
    animation-timing-function: linear; }
    @media (max-width: 800px) {
      .landing-page__section-c-graph li {
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.02em; } }
  .landing-page__section-c-graph li:nth-child(2) {
    padding-top: calc(2em * 1);
    animation-delay: calc(var(--duration) / 5 * 1); }
    @media (max-width: 599px) {
      .landing-page__section-c-graph li:nth-child(2) {
        padding-top: calc(var(--spacing-unit) * 4); } }
  .landing-page__section-c-graph li:nth-child(3) {
    padding-top: calc(2em * 2);
    animation-delay: calc(var(--duration) / 5 * 2); }
    @media (max-width: 599px) {
      .landing-page__section-c-graph li:nth-child(3) {
        padding-top: calc(var(--spacing-unit) * 4); } }
  .landing-page__section-c-graph li:nth-child(4) {
    padding-top: calc(2em * 3);
    animation-delay: calc(var(--duration) / 5 * 3); }
    @media (max-width: 599px) {
      .landing-page__section-c-graph li:nth-child(4) {
        padding-top: calc(var(--spacing-unit) * 4); } }

@keyframes pulse {
  0% {
    opacity: 1; }
  20% {
    opacity: 1; }
  40% {
    opacity: 0.1; }
  80% {
    opacity: 0.1; }
  100% {
    opacity: 1; } }

.landing-page__pitch {
  margin-bottom: calc(var(--spacing-unit) * 35); }
  @media (max-width: 900px) {
    .landing-page__pitch {
      display: none; } }

.landing-page__pitch-items {
  grid-column: 2 / 12;
  padding-right: calc(var(--spacing-unit) * 10); }

.landing-page__pitch-section {
  margin-bottom: 50vh; }
  .landing-page__pitch-section:first-child {
    margin-top: 40vh; }
  .landing-page__pitch-section:nth-child(5) {
    background-color: var(--gray-200);
    padding: calc(var(--spacing-unit) * 5);
    margin-bottom: 30vh; }
  .landing-page__pitch-section:last-child {
    margin-bottom: 60vh; }

.landing-page__pitch-section-headline {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  font-weight: var(--font-weight-medium);
  margin-bottom: calc(var(--spacing-unit) * 3);
  max-width: 16em; }
  @media (max-width: 800px) {
    .landing-page__pitch-section-headline {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }

.landing-page__pitch-section-text {
  color: var(--gray-800);
  max-width: 24em; }

.landing-page__pitch-visual-container {
  grid-column: 13 / -2;
  height: 100%; }

.landing-page__pitch-visual {
  position: sticky;
  top: calc(var(--spacing-unit) * 10);
  height: calc(100vh - (var(--spacing-unit) * 10 * 2)); }

.landing-page__pitch-visual-overlays {
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%; }

.landing-page__pitch-visual-productfield {
  position: absolute;
  left: 15.5%;
  right: 26.5%;
  top: 21.7%; }

.landing-page__pitch-visual-productfield-image {
  width: 100%;
  opacity: 0.09; }

.landing-page__pitch-visual-venn {
  position: absolute;
  left: 4.5%;
  right: 15.5%;
  top: 9.8%;
  width: calc(100% - 4.5% - 15.5%); }
  .landing-page__pitch-visual-venn text {
    font-size: 4px;
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
    letter-spacing: 0.2em; }

.landing-page__pitch-visual-venn-circle {
  fill: transparent;
  stroke-width: 0px;
  --opacity: 0.2; }

.landing-page__pitch-visual-venn-circle--viability {
  fill: rgba(249, 214, 170, var(--opacity));
  stroke: #f9d6aa; }

.landing-page__pitch-visual-venn-circle--desirability {
  fill: rgba(125, 225, 205, var(--opacity));
  stroke: #7de1cd; }

.landing-page__pitch-visual-venn-circle--feasibility {
  fill: rgba(21, 153, 193, var(--opacity));
  stroke: #1599c1; }

.landing-page__pitch-visual-venn-circle--marketability {
  fill: rgba(255, 246, 170, var(--opacity));
  stroke: #fff6aa; }

.landing-page__pitch-visual-people {
  position: absolute;
  left: 15.5%;
  right: 26.5%;
  top: 21.7%;
  bottom: 15.2%; }

.landing-page__pitch-visual-person {
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  font-weight: var(--font-weight-medium);
  border-radius: calc(var(--spacing-unit) * 5);
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 2px calc(var(--spacing-unit) * 2) 2px 2px;
  column-gap: calc(var(--spacing-unit) * 1);
  align-items: center;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  left: calc(50% + (50%) / 8 * var(--x));
  top: calc(50% + (50%) / 8 * var(--y));
  opacity: 0;
  transition: all 0.5s ease-in-out; }
  .landing-page__pitch-visual-person--viability {
    color: #6a400b;
    background-color: #f9d6aa; }
  .landing-page__pitch-visual-person--desirability {
    color: #076451;
    background-color: #7de1cd; }
  .landing-page__pitch-visual-person--feasibility {
    color: #043544;
    background-color: #9fd7e8; }
  .landing-page__pitch-visual-person--marketability {
    color: #504809;
    background-color: #fff6aa; }

.landing-page__pitch-visual-person-role {
  opacity: 1;
  transition: all 0.6s ease-in-out;
  transition-delay: inherit; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person,
[data-active-pitch-id="2"] .landing-page__pitch-visual-person,
[data-active-pitch-id="3"] .landing-page__pitch-visual-person {
  opacity: 1; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(1) {
  transition-delay: 0s; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(2) {
  transition-delay: 0.15s; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(3) {
  transition-delay: 0.3s; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(4) {
  transition-delay: 0.45s; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(5) {
  transition-delay: 0.6s; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(6) {
  transition-delay: 0.75s; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(7) {
  transition-delay: 0.9s; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(8) {
  transition-delay: 1.05s; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(9) {
  transition-delay: 1.2s; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(10) {
  transition-delay: 1.35s; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(11) {
  transition-delay: 1.5s; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(12) {
  transition-delay: 1.65s; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(13) {
  transition-delay: 1.8s; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(14) {
  transition-delay: 1.95s; }

[data-active-pitch-id="1"] .landing-page__pitch-visual-person:nth-child(15) {
  transition-delay: 2.1s; }

[data-active-pitch-id="3"] .landing-page__pitch-visual-person {
  transform: translate(-50%, -50%) scale(0.75);
  transition-delay: 0.7s; }

[data-active-pitch-id="2"] .landing-page__pitch-visual-person,
[data-active-pitch-id="3"] .landing-page__pitch-visual-person,
[data-active-pitch-id="4"] .landing-page__pitch-visual-person,
[data-active-pitch-id="5"] .landing-page__pitch-visual-person {
  height: calc(var(--spacing-unit) * 5 + 4px);
  width: calc(var(--spacing-unit) * 5 + 4px) !important; }

[data-active-pitch-id="2"] .landing-page__pitch-visual-person-role,
[data-active-pitch-id="3"] .landing-page__pitch-visual-person-role,
[data-active-pitch-id="4"] .landing-page__pitch-visual-person-role,
[data-active-pitch-id="5"] .landing-page__pitch-visual-person-role {
  transition: all 0.3s ease-in-out;
  opacity: 0; }

.landing-page__pitch-visual-person-image {
  display: block;
  height: calc(var(--spacing-unit) * 5);
  width: calc(var(--spacing-unit) * 5);
  border-radius: calc(var(--spacing-unit) * 5);
  border: 1px solid rgba(0, 0, 0, 0.1); }

.landing-page__pitch-visual-questions {
  position: absolute;
  left: 15.5%;
  right: 26.5%;
  top: 21.7%;
  bottom: 15.2%; }

.landing-page__pitch-visual-question {
  font-size: 13.5px;
  line-height: 19px;
  letter-spacing: 0.02em;
  font-weight: var(--font-weight-medium);
  border-radius: calc(var(--spacing-unit) * 0.5);
  color: var(--white);
  background-color: #263b6b;
  border: 1px solid transparent;
  padding: calc(var(--spacing-unit) * 0.5) calc(var(--spacing-unit) * 1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  white-space: nowrap;
  left: calc(50% + (50%) / 8 * var(--x));
  top: calc(50% + (50%) / 8 * var(--y));
  opacity: 0;
  transition: all 0.5s ease-in-out; }

[data-active-pitch-id="2"] .landing-page__pitch-visual-question {
  opacity: 1; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(1) {
    transition-delay: 0.6s; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(2) {
    transition-delay: 0.8s; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(3) {
    transition-delay: 1s; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(4) {
    transition-delay: 1.2s; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(5) {
    transition-delay: 1.4s; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(6) {
    transition-delay: 1.6s; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(7) {
    transition-delay: 1.8s; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(8) {
    transition-delay: 2s; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(9) {
    transition-delay: 2.2s; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(10) {
    transition-delay: 2.4s; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(11) {
    transition-delay: 2.6s; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(12) {
    transition-delay: 2.8s; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(13) {
    transition-delay: 3s; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(14) {
    transition-delay: 3.2s; }
  [data-active-pitch-id="2"] .landing-page__pitch-visual-question:nth-child(15) {
    transition-delay: 3.4s; }

[data-active-pitch-id="3"] .landing-page__pitch-visual-question,
[data-active-pitch-id="4"] .landing-page__pitch-visual-question,
[data-active-pitch-id="5"] .landing-page__pitch-visual-question {
  opacity: 1;
  transition-delay: 0.8s;
  color: transparent;
  background-color: #1d3d85;
  border-color: #20273f;
  height: 15px !important;
  width: 33px !important;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.25); }

[data-active-pitch-id="4"] .landing-page__pitch-visual-question,
[data-active-pitch-id="5"] .landing-page__pitch-visual-question {
  transition-delay: 0.3s;
  background-color: #365663; }

.landing-page__pitch-visual-answer {
  position: absolute;
  background-color: #00ffcc;
  border: 1px solid #212028;
  height: 18px !important;
  width: 22px !important;
  border-radius: calc(var(--spacing-unit) * 0.7);
  opacity: 0;
  transition: all 0.5s ease-in-out;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.25); }
  .landing-page__pitch-visual-answer--1 {
    left: calc(-22px - 5px);
    top: 50%;
    transform: translateY(-50%); }
  .landing-page__pitch-visual-answer--2 {
    right: calc(-22px - 5px);
    top: 50%;
    transform: translateY(-50%); }
  .landing-page__pitch-visual-answer--3 {
    right: calc(50% + 10px);
    top: calc(-18px - 5px); }
  .landing-page__pitch-visual-answer--4 {
    left: calc(50% + 10px);
    top: calc(-18px - 5px); }
  .landing-page__pitch-visual-answer--5 {
    right: calc(50% + 10px);
    bottom: calc(-18px - 5px); }
  .landing-page__pitch-visual-answer--6 {
    left: calc(50% + 10px);
    bottom: calc(-18px - 5px); }

[data-active-pitch-id="3"] .landing-page__pitch-visual-answer {
  opacity: 1; }
  [data-active-pitch-id="3"] .landing-page__pitch-visual-answer:nth-child(1) {
    transition-delay: 1.6s; }
  [data-active-pitch-id="3"] .landing-page__pitch-visual-answer:nth-child(2) {
    transition-delay: 1.8s; }
  [data-active-pitch-id="3"] .landing-page__pitch-visual-answer:nth-child(3) {
    transition-delay: 2s; }
  [data-active-pitch-id="3"] .landing-page__pitch-visual-answer:nth-child(4) {
    transition-delay: 2.2s; }
  [data-active-pitch-id="3"] .landing-page__pitch-visual-answer:nth-child(5) {
    transition-delay: 2.4s; }
  [data-active-pitch-id="3"] .landing-page__pitch-visual-answer:nth-child(6) {
    transition-delay: 2.6s; }

[data-active-pitch-id="4"] .landing-page__pitch-visual-answer,
[data-active-pitch-id="5"] .landing-page__pitch-visual-answer {
  opacity: 1;
  transition-delay: 0.3s;
  background-color: #365663; }

[data-active-pitch-id="4"] .landing-page__pitch-visual-question:nth-child(1) .landing-page__pitch-visual-answer:first-child,
[data-active-pitch-id="5"] .landing-page__pitch-visual-question:nth-child(1) .landing-page__pitch-visual-answer:first-child {
  box-shadow: 0 0 14px #64dd7e, 0px 0px 0px 1px #64dd7e, 0px 0px 0px 2px rgba(0, 0, 0, 0.5);
  z-index: 2; }

[data-active-pitch-id="4"] .landing-page__pitch-visual-question:nth-child(7) .landing-page__pitch-visual-answer:first-child,
[data-active-pitch-id="5"] .landing-page__pitch-visual-question:nth-child(7) .landing-page__pitch-visual-answer:first-child {
  box-shadow: 0 0 14px #64dd7e, 0px 0px 0px 1px #64dd7e, 0px 0px 0px 2px rgba(0, 0, 0, 0.5);
  z-index: 2; }

[data-active-pitch-id="4"] .landing-page__pitch-visual-question:nth-child(3) .landing-page__pitch-visual-answer:first-child,
[data-active-pitch-id="5"] .landing-page__pitch-visual-question:nth-child(3) .landing-page__pitch-visual-answer:first-child {
  box-shadow: 0 0 14px #64dd7e, 0px 0px 0px 3px #64dd7e, 0px 0px 0px 4px rgba(0, 0, 0, 0.5);
  z-index: 2; }

[data-active-pitch-id="4"] .landing-page__pitch-visual-question:nth-child(4) .landing-page__pitch-visual-answer:first-child,
[data-active-pitch-id="5"] .landing-page__pitch-visual-question:nth-child(4) .landing-page__pitch-visual-answer:first-child {
  box-shadow: 0 0 18px #ff5c57, 0px 0px 0px 3px #ff5c57, 0px 0px 0px 4px rgba(0, 0, 0, 0.5);
  z-index: 2; }

[data-active-pitch-id="4"] .landing-page__pitch-visual-question:nth-child(5) .landing-page__pitch-visual-answer:first-child,
[data-active-pitch-id="5"] .landing-page__pitch-visual-question:nth-child(5) .landing-page__pitch-visual-answer:first-child {
  box-shadow: 0 0 14px #ff5c57, 0px 0px 0px 1px #ff5c57, 0px 0px 0px 2px rgba(0, 0, 0, 0.5);
  z-index: 2; }

[data-active-pitch-id="4"] .landing-page__pitch-visual-question:nth-child(2) .landing-page__pitch-visual-answer:first-child,
[data-active-pitch-id="5"] .landing-page__pitch-visual-question:nth-child(2) .landing-page__pitch-visual-answer:first-child {
  box-shadow: 0 0 18px #ff5c57, 0px 0px 0px 3px #ff5c57, 0px 0px 0px 4px rgba(0, 0, 0, 0.5);
  z-index: 2; }

.landing-page__pitch-visual-screen,
.landing-page__pitch-visual-screen-forcefield {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: grid; }

.landing-page__pitch-visual-screen-image,
.landing-page__pitch-visual-screen-image-forcefield {
  align-self: center;
  width: 158%;
  max-height: 100%;
  display: block;
  object-fit: contain;
  object-position: left;
  opacity: 0;
  transform-style: flat;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.25);
  transition: all 0.7s ease-in-out;
  transform: scale(0.9) translateX(40vw); }

[data-active-pitch-id="3"] .landing-page__pitch-visual-screen-image {
  opacity: 1;
  transform: scale(1) translateX(0vw); }

[data-active-pitch-id="3"] .landing-page__pitch-visual-screen-image-forcefield {
  opacity: 0;
  transform: scale(1) translateX(0vw); }

[data-active-pitch-id="4"] .landing-page__pitch-visual-screen-image,
[data-active-pitch-id="5"] .landing-page__pitch-visual-screen-image {
  opacity: 1;
  transform: scale(1) translateX(0vw); }

[data-active-pitch-id="4"] .landing-page__pitch-visual-screen-image-forcefield,
[data-active-pitch-id="5"] .landing-page__pitch-visual-screen-image-forcefield {
  transition-delay: 0.5s;
  opacity: 1;
  transform: scale(1) translateX(0vw); }

.landing-page__pitch-mobile {
  display: none; }
  @media (max-width: 900px) {
    .landing-page__pitch-mobile {
      display: grid;
      margin-bottom: calc(var(--spacing-unit) * 14); } }
  @media (max-width: 599px) {
    .landing-page__pitch-mobile {
      margin-bottom: calc(var(--spacing-unit) * 12); } }
  .landing-page__pitch-mobile .landing-page__pitch-section-image {
    grid-column: 1 / -1;
    display: block;
    margin: 0 auto;
    max-width: 100%; }
  .landing-page__pitch-mobile .landing-page__pitch-section {
    grid-column: 2 / -2;
    margin-top: calc(var(--spacing-unit) * 12);
    margin-bottom: calc(var(--spacing-unit) * 4);
    background-color: transparent;
    padding: 0; }
    .landing-page__pitch-mobile .landing-page__pitch-section:first-child {
      margin-top: 0; }

.landing-page__testimonials {
  margin-bottom: calc(var(--spacing-unit) * 22); }
  @media (max-width: 900px) {
    .landing-page__testimonials {
      margin-bottom: calc(var(--spacing-unit) * 14); } }
  @media (max-width: 599px) {
    .landing-page__testimonials {
      margin-bottom: calc(var(--spacing-unit) * 12); } }

.landing-page__testimonials-content {
  grid-column: 2 / 16;
  padding-right: calc(var(--spacing-unit) * 10); }
  @media (max-width: 900px) {
    .landing-page__testimonials-content {
      grid-column: 2 / -2;
      grid-row: 1;
      padding-right: calc(var(--spacing-unit) * 0);
      margin-bottom: calc(var(--spacing-unit) * 10); } }

.landing-page__testimonials-headline {
  font-size: 50px;
  line-height: 54px;
  letter-spacing: -0.02em;
  font-weight: var(--font-weight-medium);
  margin-bottom: calc(var(--spacing-unit) * 6);
  max-width: 20em; }
  @media (max-width: 800px) {
    .landing-page__testimonials-headline {
      font-size: 32px;
      line-height: 35px;
      letter-spacing: -0.02em; } }
  @media (max-width: 599px) {
    .landing-page__testimonials-headline {
      margin-bottom: calc(var(--spacing-unit) * 4); } }

.landing-page__testimonials-text p {
  color: var(--gray-800);
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  max-width: 25em; }
  @media (max-width: 599px) {
    .landing-page__testimonials-text p {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }

.landing-page__quotes {
  grid-column: 16 / -2; }
  @media (max-width: 900px) {
    .landing-page__quotes {
      grid-column: 2 / -2;
      grid-row: 2; } }

.landing-page__quote {
  margin: 0; }
  .landing-page__quote p {
    color: var(--red);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.01em;
    max-width: 25em;
    hanging-punctuation: first; }
    @media (max-width: 800px) {
      .landing-page__quote p {
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.02em; } }
    @media (max-width: 900px) {
      .landing-page__quote p {
        font-size: 22px;
        line-height: 30px;
        letter-spacing: -0.005em; } }
  @media (max-width: 900px) and (max-width: 599px) {
    .landing-page__quote p {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
    @media (max-width: 599px) {
      .landing-page__quote p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.009em;
        text-underline-offset: 1.5px; } }

.landing-page__quote + .landing-page__quote {
  margin-top: calc(var(--spacing-unit) * 10); }
  @media (max-width: 900px) {
    .landing-page__quote + .landing-page__quote {
      margin-top: calc(var(--spacing-unit) * 6); } }

.landing-page__quote-cite {
  text-decoration: none;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 4); }

.landing-page__quote-company {
  color: var(--gray-700); }

.landing-page__quote-logo {
  max-height: 2.6em;
  max-width: calc(var(--spacing-unit) * 30); }
  @media (max-width: 599px) {
    .landing-page__quote-logo {
      display: none; } }

.landing-page__finale {
  overflow: hidden; }

.landing-page__finale-key-visual {
  grid-column: -1 / 1;
  grid-row: 1 / 3;
  z-index: -1;
  background-color: var(--gray-900);
  position: relative; }
  .landing-page__finale-key-visual img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    opacity: 0.035;
    filter: invert(1); }

.landing-page__finale-headline {
  font-size: 50px;
  line-height: 54px;
  letter-spacing: -0.02em;
  font-weight: var(--font-weight-medium);
  color: var(--green);
  padding-top: calc(var(--spacing-unit) * 12);
  margin-bottom: calc(var(--spacing-unit) * 12);
  grid-column: 2 / -2;
  grid-row: 1;
  max-width: 16em;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 800px) {
    .landing-page__finale-headline {
      font-size: 32px;
      line-height: 35px;
      letter-spacing: -0.02em; } }
  @media (max-width: 900px) {
    .landing-page__finale-headline {
      padding-top: calc(var(--spacing-unit) * 9);
      margin-bottom: calc(var(--spacing-unit) * 9); } }
  @media (max-width: 599px) {
    .landing-page__finale-headline {
      padding-top: calc(var(--spacing-unit) * 7);
      margin-bottom: calc(var(--spacing-unit) * 7); } }

.landing-page__finale-cta-form {
  grid-column: 8 / -8;
  grid-row: 2;
  padding-bottom: calc(var(--spacing-unit) * 30); }
  @media (max-width: 900px) {
    .landing-page__finale-cta-form {
      grid-column: 2 / -2;
      padding-bottom: calc(var(--spacing-unit) * 24); } }
  @media (max-width: 599px) {
    .landing-page__finale-cta-form {
      padding-bottom: calc(var(--spacing-unit) * 20); } }

.landing-page__finale-cta-button {
  position: relative;
  margin: 0 auto;
  width: max-content; }
  .landing-page__finale-cta-button button {
    position: relative;
    z-index: 2; }

@media (max-width: 599px) {
  .landing-page__finale-cta-form--inline .landing-page__finale-cta-button {
    margin-top: calc(var(--spacing-unit) * 2); } }

.landing-page__finale-indicator--1,
.landing-page__finale-indicator--2,
.landing-page__finale-indicator--3 {
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  width: 100vw;
  height: 100vw;
  z-index: 0; }

.landing-page__finale-indicator--1::before,
.landing-page__finale-indicator--1::after,
.landing-page__finale-indicator--2::before,
.landing-page__finale-indicator--2::after,
.landing-page__finale-indicator--3::before,
.landing-page__finale-indicator--3::after {
  content: "";
  display: block;
  position: absolute;
  transform: translate(50%, 50%);
  height: 100%;
  width: 100%;
  border-radius: calc(var(--spacing-unit) * 140);
  background-color: rgba(255, 255, 255, 0.1);
  animation-name: radar;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.035, 0.37, 0.05, 0.33); }

.landing-page__finale-indicator--1::before {
  animation-delay: 0; }

.landing-page__finale-indicator--1::after {
  animation-delay: 2s; }

.landing-page__finale-indicator--2::before {
  animation-delay: 4s; }

.landing-page__finale-indicator--2::after {
  animation-delay: 6s; }

.landing-page__finale-indicator--3::before {
  animation-delay: 8s; }

.landing-page__finale-indicator--3::after {
  animation-delay: 10s; }

@keyframes radar {
  0% {
    opacity: 1;
    transform: scale(0); }
  40% {
    opacity: 0; }
  100% {
    opacity: 0;
    transform: scale(1); } }

.legal__headline {
  grid-column: 9 / -2;
  font-size: 80px;
  line-height: 76px;
  letter-spacing: -0.024em;
  word-spacing: 0.07em;
  font-weight: var(--font-weight-medium);
  margin-top: calc(var(--spacing-unit) * 15);
  margin-bottom: calc(var(--spacing-unit) * 12); }
  @media (max-width: 1200px) {
    .legal__headline {
      font-size: 74px;
      line-height: 72px;
      letter-spacing: -0.02em; } }
  @media (max-width: 900px) {
    .legal__headline {
      font-size: 64px;
      line-height: 60px;
      letter-spacing: -0.02em; } }
  @media (max-width: 800px) {
    .legal__headline {
      font-size: 45px;
      line-height: 47px;
      letter-spacing: -0.015em; } }
  @media (max-width: 900px) {
    .legal__headline {
      grid-column: 2 / -2; } }
  @media (max-width: 599px) {
    .legal__headline {
      grid-column: 2 / -2;
      margin-top: calc(var(--spacing-unit) * 10);
      margin-bottom: calc(var(--spacing-unit) * 6); } }

.legal__content {
  grid-column: 2 / -2;
  display: grid;
  grid-template-columns: repeat(24, 1fr); }
  .legal__content h2 {
    grid-column: 1 / 8;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.009em;
    text-underline-offset: 1.5px;
    font-weight: var(--font-weight-medium);
    padding-right: calc(var(--spacing-unit) * 5); }
    @media (max-width: 599px) {
      .legal__content h2 {
        grid-column: 1 / -1; } }
  .legal__content h3 {
    grid-column: 8 / -6;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.009em;
    text-underline-offset: 1.5px;
    font-weight: var(--font-weight-medium); }
    @media (max-width: 1200px) {
      .legal__content h3 {
        grid-column: 8 / -2; } }
    @media (max-width: 599px) {
      .legal__content h3 {
        grid-column: 1 / -1; } }
  .legal__content p,
  .legal__content ul {
    grid-column: 8 / -6; }
    @media (max-width: 1200px) {
      .legal__content p,
      .legal__content ul {
        grid-column: 8 / -2;
        max-width: 100%; } }
    @media (max-width: 599px) {
      .legal__content p,
      .legal__content ul {
        grid-column: 1 / -1; } }
  .legal__content ul {
    margin: 0;
    padding: 0;
    margin-bottom: 0.7em; }
  .legal__content h2 {
    border-top: 1px solid var(--black);
    padding-top: calc(var(--spacing-unit) * 2);
    margin-top: calc(var(--spacing-unit) * 10); }
  .legal__content h2 + p,
  .legal__content h2 + h3,
  .legal__content h2 + ul {
    border-top: 1px solid var(--black);
    padding-top: calc(var(--spacing-unit) * 2);
    margin-top: calc(var(--spacing-unit) * 10); }
    @media (max-width: 599px) {
      .legal__content h2 + p,
      .legal__content h2 + h3,
      .legal__content h2 + ul {
        border-top: none;
        padding-top: 0;
        margin-top: calc(var(--spacing-unit) * 4); } }

.product {
  padding-top: calc(var(--spacing-unit) * 4); }
  @media (max-width: 599px) {
    .product {
      padding-top: calc(var(--spacing-unit) * 4); } }

.card-headline {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  font-weight: var(--font-weight-medium);
  margin-bottom: calc(var(--spacing-unit) * 5);
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 2); }
  @media (max-width: 800px) {
    .card-headline {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }
  .card-headline::after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  @media (max-width: 599px) {
    .card-headline {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.009em;
      text-underline-offset: 1.5px;
      margin-bottom: calc(var(--spacing-unit) * 3); } }

.product__intro {
  grid-template-rows: auto auto calc(var(--spacing-unit) * 15) 0 0; }
  @media (max-width: 1200px) {
    .product__intro {
      grid-template-rows: auto auto calc(var(--spacing-unit) * 15) 0 0; } }
  @media (max-width: 900px) {
    .product__intro {
      grid-template-rows: auto auto calc(var(--spacing-unit) * 10) 0 0; } }
  @media (max-width: 599px) {
    .product__intro {
      grid-template-rows: auto auto 0 calc(var(--spacing-unit) * 8) calc(var(--spacing-unit) * 8); } }

.product__intro-background {
  background-color: var(--gray-900);
  grid-column: 2 / -2;
  grid-row: 1 / 3;
  position: relative;
  z-index: -2;
  overflow: hidden;
  animation: moveUp 1s 0.5s ease-out;
  animation-fill-mode: forwards;
  transform: translateY(10%); }

@keyframes moveUp {
  to {
    transform: translateY(0%); } }
  @media (max-width: 599px) {
    .product__intro-background {
      grid-row: 2 / -2; } }

.product__headline {
  grid-column: 2 / -6;
  grid-row: 1;
  font-size: 80px;
  line-height: 76px;
  letter-spacing: -0.024em;
  word-spacing: 0.07em;
  font-weight: var(--font-weight-medium);
  color: var(--white);
  margin-top: calc(var(--spacing-unit) * 7);
  margin-left: calc(var(--spacing-unit) * 8);
  hyphens: manual;
  -webkit-hyphens: manual;
  animation: moveUp 1s 0.5s ease-out;
  animation-fill-mode: forwards;
  transform: translateY(15%);
  opacity: 0; }
  @media (max-width: 1200px) {
    .product__headline {
      font-size: 74px;
      line-height: 72px;
      letter-spacing: -0.02em; } }
  @media (max-width: 900px) {
    .product__headline {
      font-size: 64px;
      line-height: 60px;
      letter-spacing: -0.02em; } }
  @media (max-width: 800px) {
    .product__headline {
      font-size: 45px;
      line-height: 47px;
      letter-spacing: -0.015em; } }
  .product__headline strong {
    color: var(--green); }

@keyframes moveUp {
  to {
    transform: translateY(0%);
    opacity: 1; } }
  @media (max-width: 1200px) {
    .product__headline {
      grid-column: 2 / -2;
      margin-right: calc(var(--spacing-unit) * 8); } }
  @media (max-width: 900px) {
    .product__headline {
      margin-top: calc(var(--spacing-unit) * 5);
      margin-left: calc(var(--spacing-unit) * 5);
      margin-right: calc(var(--spacing-unit) * 5); } }
  @media (max-width: 599px) {
    .product__headline {
      font-size: 62px;
      line-height: 64px;
      letter-spacing: -0.025em;
      margin-top: calc(var(--spacing-unit) * 2);
      margin-left: calc(var(--spacing-unit) * 0);
      margin-right: calc(var(--spacing-unit) * 0);
      margin-bottom: calc(var(--spacing-unit) * 5);
      color: var(--gray-900); } }
  @media (max-width: 599px) and (max-width: 800px) {
    .product__headline {
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -0.02em; } }
  @media (max-width: 599px) {
      .product__headline strong {
        color: var(--black);
        text-decoration: underline;
        text-decoration-color: var(--green);
        text-underline-offset: 0.12em;
        text-decoration-thickness: 0.1em; } }

.product__headline em {
  font-weight: var(--font-weight-bold);
  color: var(--red); }

.product__intro-illustration {
  grid-row: 2 / 4;
  grid-column: 5 / -2;
  padding-right: calc(var(--spacing-unit) * 5);
  padding-left: calc(var(--spacing-unit) * 5);
  margin-top: -6%; }
  .product__intro-illustration img {
    display: block;
    width: 100%; }
  @media (max-width: 1200px) {
    .product__intro-illustration {
      margin-top: 0;
      grid-column: 4 / -2; } }
  @media (max-width: 900px) {
    .product__intro-illustration {
      grid-column: 3 / -2;
      margin-top: calc(var(--spacing-unit) * 7);
      padding-right: calc(var(--spacing-unit) * 1);
      padding-left: calc(var(--spacing-unit) * 1); } }
  @media (max-width: 599px) {
    .product__intro-illustration {
      margin-top: calc(var(--spacing-unit) * 3); } }

.product__intro-illustration-filler {
  grid-row: 4 / -1;
  grid-column: 3 / -2;
  margin-right: calc(var(--spacing-unit) * 1);
  margin-left: calc(var(--spacing-unit) * 1);
  background-repeat: repeat-y;
  background-size: 100%; }

.product__testimonials {
  grid-template-rows: calc(var(--spacing-unit) * 8) auto calc(var(--spacing-unit) * 8); }
  @media (max-width: 900px) {
    .product__testimonials {
      grid-template-rows: calc(var(--spacing-unit) * 5) auto calc(var(--spacing-unit) * 5); } }
  @media (max-width: 599px) {
    .product__testimonials {
      grid-template-rows: calc(var(--spacing-unit) * 4) auto calc(var(--spacing-unit) * 4); } }

.product__testimonials-background {
  background-color: var(--green);
  grid-column: 2 / -3;
  grid-row: 2 / 4;
  position: relative;
  z-index: -2;
  overflow: hidden; }
  .product__testimonials-background .key-visual {
    opacity: 0.08; }

.product__testimonials-content {
  background-color: var(--gray-900);
  color: var(--white);
  grid-column: 3 / -2;
  grid-row: 1 / 3;
  padding: calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 6); }
  @media (max-width: 599px) {
    .product__testimonials-content {
      padding: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 4); } }

.product__testimonials-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: calc(var(--spacing-unit) * 4);
  row-gap: calc(var(--spacing-unit) * 6); }
  @media (max-width: 599px) {
    .product__testimonials-cards {
      row-gap: calc(var(--spacing-unit) * 4); } }

.product__testimonials-card {
  width: 30%;
  flex-grow: 1;
  min-width: calc(var(--spacing-unit) * 60);
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  color: var(--green); }
  @media (max-width: 800px) {
    .product__testimonials-card {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }
  @media (max-width: 1200px) {
    .product__testimonials-card {
      width: 50%;
      min-width: unset; } }
  @media (max-width: 900px) {
    .product__testimonials-card {
      width: 100%; } }
  @media (max-width: 599px) {
    .product__testimonials-card {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.009em;
      text-underline-offset: 1.5px; } }

.product__testimonials-quote {
  max-width: 23em;
  hanging-punctuation: first; }
  .product__testimonials-quote strong {
    color: var(--white);
    font-weight: var(--font-weight-regular); }
  .product__testimonials-quote a {
    text-decoration-thickness: 2px;
    text-underline-offset: 0.07em; }
  .product__testimonials-quote::before {
    content: "“"; }
  .product__testimonials-quote::after {
    content: "”"; }

.product__testimonials-credit {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.009em;
  text-underline-offset: 1.5px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: calc(var(--spacing-unit) * 2);
  margin-top: calc(var(--spacing-unit) * 3);
  color: var(--white); }
  @media (max-width: 599px) {
    .product__testimonials-credit {
      margin-top: calc(var(--spacing-unit) * 2); } }
  @media (max-width: 599px) {
    .product__testimonials-credit {
      font-size: 13.5px;
      line-height: 19px;
      letter-spacing: 0.02em;
      column-gap: calc(var(--spacing-unit) * 1);
      margin-top: calc(var(--spacing-unit) * 1); } }

.product__testimonials-face-image {
  display: block;
  height: calc(var(--spacing-unit) * 10);
  width: calc(var(--spacing-unit) * 10);
  object-fit: cover;
  border-radius: 50%;
  grid-column: 1; }
  @media (max-width: 900px) {
    .product__testimonials-face-image {
      height: calc(var(--spacing-unit) * 9);
      width: calc(var(--spacing-unit) * 9); } }
  @media (max-width: 599px) {
    .product__testimonials-face-image {
      height: calc(var(--spacing-unit) * 6);
      width: calc(var(--spacing-unit) * 6); } }

.product__testimonials-name {
  font-weight: var(--font-weight-medium);
  grid-column: 2; }
  @media (max-width: 599px) {
    .product__testimonials-name {
      color: var(--gray-500); } }

.product__testimonials-role {
  color: var(--gray-400); }
  @media (max-width: 599px) {
    .product__testimonials-role {
      color: var(--gray-600); } }
  .product__testimonials-role a {
    text-decoration-color: var(--gray-700); }

.product__slogan {
  margin-top: calc(var(--spacing-unit) * 20);
  margin-bottom: calc(var(--spacing-unit) * 25); }
  @media (max-width: 599px) {
    .product__slogan {
      margin-top: calc(var(--spacing-unit) * 15);
      margin-bottom: calc(var(--spacing-unit) * 15); } }

.product__slogan-text {
  grid-column: 2 / -6;
  hyphens: none;
  -webkit-hyphens: none;
  font-size: 62px;
  line-height: 64px;
  letter-spacing: -0.025em;
  font-weight: var(--font-weight-medium);
  word-spacing: 0.04em; }
  @media (max-width: 800px) {
    .product__slogan-text {
      font-size: 38px;
      line-height: 42px;
      letter-spacing: -0.02em; } }
  .product__slogan-text em {
    color: var(--red); }
  .product__slogan-text a {
    text-underline-offset: 0.06em;
    text-decoration-color: var(--gray-300);
    text-decoration-thickness: 0.06em;
    transition: all 0.8s; }
    .product__slogan-text a:hover {
      text-decoration-color: var(--red); }
  @media (max-width: 1200px) {
    .product__slogan-text {
      hyphens: auto;
      -webkit-hyphens: auto;
      grid-column: 2 / -2; } }
  @media (max-width: 599px) {
    .product__slogan-text {
      font-size: 50px;
      line-height: 54px;
      letter-spacing: -0.02em; } }
  @media (max-width: 599px) and (max-width: 800px) {
    .product__slogan-text {
      font-size: 32px;
      line-height: 35px;
      letter-spacing: -0.02em; } }

.product__teasers {
  margin-top: calc(var(--spacing-unit) * 30);
  grid-template-rows: calc(var(--spacing-unit) * 10) auto calc(var(--spacing-unit) * 10) auto 1fr; }
  @media (max-width: 599px) {
    .product__teasers {
      margin-top: calc(var(--spacing-unit) * 15); } }
  @media (max-width: 900px) {
    .product__teasers {
      grid-template-rows: auto calc(var(--spacing-unit) * 10) auto calc(var(--spacing-unit) * 10) auto; } }
  @media (max-width: 599px) {
    .product__teasers {
      grid-template-rows: auto calc(var(--spacing-unit) * 5) auto calc(var(--spacing-unit) * 5) auto; } }

.product__teasers-features-background {
  grid-column: 2 / -8;
  grid-row: 2 / 4;
  background-color: var(--green);
  z-index: 1; }
  @media (max-width: 1200px) {
    .product__teasers-features-background {
      grid-column: 2 / -6; } }
  @media (max-width: 900px) {
    .product__teasers-features-background {
      grid-row: 1 / 3;
      grid-column: 2 / -4; } }
  @media (max-width: 599px) {
    .product__teasers-features-background {
      grid-column: 2 / -3; } }

.product__teasers-features-card {
  grid-column: 2 / -12;
  grid-row: 2 / 3;
  background-color: var(--green);
  z-index: 1;
  padding: calc(var(--spacing-unit) * 6) calc(var(--spacing-unit) * 7) calc(var(--spacing-unit) * 12); }
  @media (max-width: 1200px) {
    .product__teasers-features-card {
      grid-column: 2 / -14; } }
  @media (max-width: 900px) {
    .product__teasers-features-card {
      grid-row: 1;
      grid-column: 2 / -4;
      padding-bottom: calc(var(--spacing-unit) * 6); } }
  @media (max-width: 599px) {
    .product__teasers-features-card {
      grid-column: 2 / -3;
      padding: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 8); } }

.product__teasers-features-text {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  max-width: 26em; }
  @media (max-width: 599px) {
    .product__teasers-features-text {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  @media (max-width: 599px) {
    .product__teasers-features-text {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.009em;
      text-underline-offset: 1.5px; } }

.product__teasers-features-link {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  font-weight: var(--font-weight-medium); }
  @media (max-width: 599px) {
    .product__teasers-features-link {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  .product__teasers-features-link a {
    text-decoration: none;
    border-bottom: 0.12em solid black; }

.product__teasers-features-link-arrow {
  display: inline-block;
  margin-left: 3px;
  vertical-align: -0.2em;
  height: 1em;
  width: 1em; }
  .product__teasers-features-link-arrow svg {
    display: inline-block;
    height: 100%;
    width: 100%; }
  .product__teasers-features-link-arrow path {
    fill: var(--white); }

.product__teasers-features-read-more {
  margin-top: calc(var(--spacing-unit) * 10); }
  @media (max-width: 1200px) {
    .product__teasers-features-read-more {
      margin-bottom: calc(var(--spacing-unit) * 4); } }
  @media (max-width: 599px) {
    .product__teasers-features-read-more {
      margin-top: calc(var(--spacing-unit) * 10); } }

.product__teasers-benefits-card {
  grid-column: -12 / -2;
  grid-row: 1 / 5;
  z-index: 3;
  background-color: var(--gray-900);
  color: var(--white);
  padding: calc(var(--spacing-unit) * 6) calc(var(--spacing-unit) * 6); }
  @media (max-width: 1200px) {
    .product__teasers-benefits-card {
      grid-column: -14 / -2; } }
  @media (max-width: 900px) {
    .product__teasers-benefits-card {
      grid-column: 4 / -2;
      grid-row: 2 / 5; } }
  @media (max-width: 599px) {
    .product__teasers-benefits-card {
      grid-column: 3 / -2;
      padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 4); } }

.product__teasers-benefits {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(var(--spacing-unit) * 4);
  column-gap: calc(var(--spacing-unit) * 5); }

.product__benefit {
  display: grid;
  grid-template-columns: calc(var(--spacing-unit) * 9) 1fr;
  column-gap: calc(var(--spacing-unit) * 3);
  text-decoration: none;
  width: 40%;
  flex-grow: 1;
  min-width: calc(var(--spacing-unit) * 50); }
  @media (max-width: 599px) {
    .product__benefit {
      grid-template-columns: 1fr;
      width: 60%;
      min-width: unset; } }

.product__benefit-icon {
  width: 100%;
  align-self: center;
  display: block; }
  @media (max-width: 599px) {
    .product__benefit-icon {
      display: none; } }

.product__benefit-icon-image {
  width: 100%; }

.product__benefit-content {
  max-width: 26em; }

.product__benefit-title {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  font-weight: var(--font-weight-medium);
  color: var(--green); }
  @media (max-width: 599px) {
    .product__benefit-title {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }

.product__teasers-benefits-link {
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.005em;
  font-weight: var(--font-weight-medium);
  margin-top: calc(var(--spacing-unit) * 6); }
  @media (max-width: 599px) {
    .product__teasers-benefits-link {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  @media (max-width: 599px) {
    .product__teasers-benefits-link {
      margin-top: calc(var(--spacing-unit) * 5); } }

.product__teasers-benefits-link-arrow {
  display: inline-block;
  margin-left: 3px;
  vertical-align: -0.2em;
  height: 1em;
  width: 1em; }
  .product__teasers-benefits-link-arrow svg {
    display: inline-block;
    height: 100%;
    width: 100%; }
  .product__teasers-benefits-link-arrow path {
    fill: var(--red); }

.product__teasers-cta-background {
  grid-column: 5 / -11;
  grid-row: 3 / 6;
  background-color: var(--gray-800);
  z-index: 2;
  position: relative;
  overflow: hidden; }
  .product__teasers-cta-background .key-visual {
    opacity: 0.2; }
  @media (max-width: 1200px) {
    .product__teasers-cta-background {
      grid-column: 3 / -13; } }
  @media (max-width: 900px) {
    .product__teasers-cta-background {
      grid-column: 3 / -4;
      grid-row: 4 / 6; } }
  @media (max-width: 599px) {
    .product__teasers-cta-background {
      grid-column: 2 / -3; } }

.product__teasers-cta-card {
  grid-column: 5 / -12;
  grid-row: 3 / 6;
  z-index: 2;
  color: var(--white);
  padding: calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 6);
  overflow: hidden; }
  .product__teasers-cta-card .card-headline {
    margin-bottom: calc(var(--spacing-unit) * 3); }
  @media (max-width: 1200px) {
    .product__teasers-cta-card {
      grid-column: 3 / -14; } }
  @media (max-width: 900px) {
    .product__teasers-cta-card {
      grid-column: 3 / -4;
      grid-row: 5 / 6;
      padding-top: calc(var(--spacing-unit) * 8); } }
  @media (max-width: 599px) {
    .product__teasers-cta-card {
      grid-column: 2 / -3;
      padding: calc(var(--spacing-unit) * 8) calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 3); } }

.product__teasers-cta-headline {
  position: relative;
  z-index: 2; }

.product__teasers-cta-intro {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.01em;
  margin-bottom: 1.5em;
  position: relative;
  z-index: 2; }
  @media (max-width: 800px) {
    .product__teasers-cta-intro {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02em; } }
  .product__teasers-cta-intro strong {
    font-weight: var(--font-weight-regukar); }
  @media (max-width: 900px) {
    .product__teasers-cta-intro {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: -0.005em; } }
  @media (max-width: 900px) and (max-width: 599px) {
    .product__teasers-cta-intro {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.005em; } }
  @media (max-width: 599px) {
    .product__teasers-cta-intro {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.009em;
      text-underline-offset: 1.5px; } }

.product__teasers-cta-button {
  position: relative; }
  .product__teasers-cta-button .button {
    position: relative;
    z-index: 2; }

@font-face {
  font-family: "Söhne";
  src: url("https://fonts.fieldbyfield.com/soehne-web-leicht.eot");
  src: url("https://fonts.fieldbyfield.com/soehne-web-leichte.eot?#iefix") format("embedded-opentype"), url("https://fonts.fieldbyfield.com/soehne-web-leicht.woff2") format("woff2"), url("https://fonts.fieldbyfield.com/soehne-web-leicht.woff") format("woff");
  font-weight: 300; }

@font-face {
  font-family: "Söhne";
  src: url("https://fonts.fieldbyfield.com/soehne-web-buch.eot");
  src: url("https://fonts.fieldbyfield.com/soehne-web-buch/filename.eot?#iefix") format("embedded-opentype"), url("https://fonts.fieldbyfield.com/soehne-web-buch.woff2") format("woff2"), url("https://fonts.fieldbyfield.com/soehne-web-buch.woff") format("woff");
  font-weight: 400; }

@font-face {
  font-family: "Söhne";
  src: url("https://fonts.fieldbyfield.com/soehne-web-kraftig.eot");
  src: url("https://fonts.fieldbyfield.com/soehne-web-kraftig.eot?#iefix") format("embedded-opentype"), url("https://fonts.fieldbyfield.com/soehne-web-kraftig.woff2") format("woff2"), url("https://fonts.fieldbyfield.com/soehne-web-kraftig.woff") format("woff");
  font-weight: 500; }

@font-face {
  font-family: "Söhne Breit";
  src: url("https://fonts.fieldbyfield.com/soehne-breit-web-buch.eot");
  src: url("https://fonts.fieldbyfield.com/soehne-breit-web-buch.eot?#iefix") format("embedded-opentype"), url("https://fonts.fieldbyfield.com/soehne-breit-web-buch.woff2") format("woff2"), url("https://fonts.fieldbyfield.com/soehne-breit-web-buch.woff") format("woff");
  font-weight: 400; }

@font-face {
  font-family: "Söhne Breit";
  src: url("https://fonts.fieldbyfield.com/soehne-breit-web-kraftig.eot");
  src: url("https://fonts.fieldbyfield.com/soehne-breit-web-kraftig.eot?#iefix") format("embedded-opentype"), url("https://fonts.fieldbyfield.com/soehne-breit-web-kraftig.woff2") format("woff2"), url("https://fonts.fieldbyfield.com/soehne-breit-web-kraftig.woff") format("woff");
  font-weight: 500; }

@font-face {
  font-family: "Söhne Breit";
  src: url("https://fonts.fieldbyfield.com/soehne-breit-web-halbfett.eot");
  src: url("https://fonts.fieldbyfield.com/soehne-breit-web-halbfett.eot?#iefix") format("embedded-opentype"), url("https://fonts.fieldbyfield.com/soehne-breit-web-halbfett.woff2") format("woff2"), url("https://fonts.fieldbyfield.com/soehne-breit-web-halbfett.woff") format("woff");
  font-weight: 700; }

*,
*::after,
*::before {
  box-sizing: border-box; }

html,
body {
  margin: 0;
  padding: 0;
  font-size: 12px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  font-family: var(--font-family-default);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.009em;
  text-underline-offset: 1.5px;
  hyphens: auto;
  -webkit-hyphens: auto;
  scroll-padding-top: 60px; }

em {
  font-family: var(--font-family-wide);
  font-style: normal;
  font-weight: var(--font-weight-medium); }

strong {
  font-weight: var(--font-weight-medium); }

h1,
h2,
h3,
h4 {
  margin: 0; }

p {
  margin: 0;
  margin-bottom: 0.6em;
  max-width: 36em; }
  p:last-child {
    margin-bottom: 0; }

a {
  color: inherit; }
